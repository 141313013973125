import { useEffect } from "react";
import { useApp } from "../../state/AppProvider";
import { NoticeBar } from "antd-mobile";

export function ErrorNoticeBar() {
  const { showErrorMsg, setShowErrorMsg, errorMsg, setErrorMsg } = useApp();
  useEffect(() => {
    setErrorMsg("");
    setShowErrorMsg(false);
  }, []);

  const jsx = showErrorMsg ? (
    <NoticeBar
      content={errorMsg}
      color="alert"
      wrap
      closeable
      style={{ marginTop: 48 }}
    />
  ) : (
    <></>
  );
  return jsx;
}
