import { createContext, useState, useContext } from "react";
import backend from "../../functions/backend";
import { getCurrentMonthQueryString } from "../../functions/timeDate";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";

const PollingContext = createContext();

export const usePollingA = () => useContext(PollingContext);

const PollingProvider = ({ children }) => {
  const [isPolling, setIsPolling] = useState(false);
  const [numUncategorizedTx, setNumUncategorizedTx] = useState(0);
  const [moneyWheelIntervalId, setMoneyWheelIntervalId] = useState(null);
  const checkResponseFail = useCheckResponseFail();

  const fetchMoneyWheelData = async () => {
    const query =
      "/v1/tx/transaction?categorization_type=uncategorized&_count=true&posted_date=" +
      getCurrentMonthQueryString();

    const response = await backend.get(query);
    console.log("fetchMoneyWheelData(): Data was fetched");
    if (
      checkResponseFail(
        response,
        "Failed to GET uncategorized transactions count.",
      )
    ) {
      return 0;
    }
    return response.count;
  };

  const startPolling = () => {
    //const POLLING_INTERVAL = 1200000; // 20 min
    const POLLING_INTERVAL = 60000; // 1 min

    // create only 1 background polling process
    if (isPolling) return;

    setIsPolling(true);

    const poll = async () => {
      try {
        const result = await fetchMoneyWheelData();
        if (result !== numUncategorizedTx) {
          console.log("numUncategorizedTx has changed!");
          setNumUncategorizedTx(result);
        }
      } catch (error) {
        console.error("poll failed:", error);
      }
    };

    const id = setInterval(poll, POLLING_INTERVAL);
    setMoneyWheelIntervalId(id);

    poll(); // do first poll immediately
  };

  const stopPolling = async () => {
    if (moneyWheelIntervalId) {
      console.log("PollingProvider cleanup function is running.");
      clearInterval(moneyWheelIntervalId);
      setMoneyWheelIntervalId(null);
    }
  };

  return (
    <PollingContext.Provider
      value={{
        startPolling,
        stopPolling,
        isPolling,
        numUncategorizedTx,
        setNumUncategorizedTx,
      }}
    >
      {children}
    </PollingContext.Provider>
  );
};

export default PollingProvider;
