// Custom React hook for handling errors from API call's response.
import { useNavigate } from "react-router-dom";
import { useDisplayErrorMsg } from "./useDisplayErrorMsg";

export const useCheckResponseFail = () => {
  const navigate = useNavigate();
  const { displayErrorMsg } = useDisplayErrorMsg();

  // IN: msg: (optional) message to print, if error.
  // Returns true if there was an error.  false if no error.
  const checkResponseFail = (response, msg = "") => {
    // tldr;   Keep the `===`.
    // If response.success is undefined, this condition (`=== false`) should fail,
    // so that 3rd party APIs that don't have a response.success property
    // won't trigger the error handling.
    // If the http status code is >= 400, then it's a client or server error.
    if (response.success === false || response.http_status_code >= 400) {
      console.warn("RESPONSE_ERR: " + msg, response);
      console.log("response.http_status_code is", response.http_status_code);

      if (response.http_status_code === 401) {
        navigate("/");
      } else {
        // 403 Forbidden will just show ErrorNoticeBar
        displayErrorMsg(response.message);
      }
      return true;
    }
    return false;
  };

  return checkResponseFail;
};
