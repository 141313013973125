import { useEffect, useState } from "react";
import { useUser } from "../../../state/UserProvider";
import { useSettings } from "../../../state/SettingsProvider";
import { Form } from "antd";
import { Stepper } from "antd-mobile";
import "./style.css";
import FormFooter from "../../../components/UI/FormFooter";
import * as S from "./styledFormComponents";
import { BasicFamilyIcon } from "./BasicFamilyIcon";

export const HowManyDependentsForm = ({
  onFinish,
  onFinishFailed,
  previousPage,
}) => {
  // Form is an antd component
  // useForm() is a React hook
  const [form1] = Form.useForm();
  const { currentUser } = useUser();
  const { wheelSettings } = useSettings();

  /* If the user enters a positive number and then backs up before
     entering the ages of all the dependents,
     then currentUser.dependents.length is misleading, in that case.
     So for the Number of Dependents use wheelSettings.dependent_count,
     which is always accurate.
  */
  let fields = { Dependents: wheelSettings.dependent_count };
  const maritalStatus = currentUser.marital_status;

  useEffect(() => {
    if (fields) {
      form1.setFieldsValue(fields);
      console.log("useEffect() in HowManyDependentsForm");
      console.log(fields);
      //console.log(`maritalStatus is ${maritalStatus}`);
    }
  }, []);

  const [dependents, setDependents] = useState(wheelSettings.dependent_count);

  return (
    <div className={"form-wrapper"}>
      <BasicFamilyIcon
        maritalStatus={maritalStatus}
        dependents={dependents}
      ></BasicFamilyIcon>
      <S.FormQuestion>How many dependents do you have?</S.FormQuestion>
      <S.FormQuestionHelp>
        A dependent is a person, such as a child or elderly relative, whom you
        financially support and can claim on your taxes.
      </S.FormQuestionHelp>
      <Form
        form={form1}
        layout="vertical"
        name="Dependents"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={(changedValues, allValues) => {
          setDependents(allValues.Dependents);
        }}
      >
        <Form.Item
          name="Dependents"
          //label=""  // no label
        >
          <Stepper min={0} max={19} style={S.stepperStyle} />
        </Form.Item>
        <FormFooter
          onPrevButton={(e) => previousPage(e)}
          onNextButton={"enabled"}
        />
      </Form>
    </div>
  );
};
