// returns number of days left in current month
export function daysLeftInMonth() {
  const now = new Date();
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  const daysLeft = lastDay.getDate() - now.getDate();
  return daysLeft + 1;
}

// IN: prevDate: Date() format: a date earlier than or equal to today
//
// returns number of days between prevDate and today
export function daysSince(prevDate) {
  prevDate.setHours(0, 0, 0, 0); // clear time part

  const today = new Date();
  today.setHours(0, 0, 0, 0); // clear time part

  const timeDifference = today - prevDate; // in milliseconds

  // convert from milliseconds to days
  const daysDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));
  console.log(`It's been ${daysDifference} days since the account was created`);

  return daysDifference;
}

const pad = (num) => (num < 10 ? "0" : "") + num;

// returns now, in YYYY-MM-DD HH:MM:SS format
export function getFormattedTimestamp() {
  const date = new Date();
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are zero-based
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// returns now, in YYYY-MM-DDTHH:MM:SS.MMMZ format
export function getTimestamp() {
  const date = new Date();
  return date.toISOString();
}

export function getNumMonthsQueryString(startMonth, startYear, numMonths) {
  console.assert(
    startMonth >= 1 && startMonth <= 12,
    "getNumMonthsQueryString(): startMonth must be between 1 and 12.",
  );
  console.assert(
    startYear >= 1900 && startYear <= 2200,
    "getNumMonthsQueryString(): startMonth must be between 1900 and 2200.",
  );
  console.assert(
    numMonths >= 1 && numMonths <= 120,
    "getNumMonthsQueryString(): numMonths must be between 1 and 120.",
  );

  const start =
    "" + startYear + "-" + startMonth.toString().padStart(2, "0") + "-01";
  // handle multi-year
  const endMonth = (Number(startMonth) + numMonths) % 12;
  const endYear =
    Number(startYear) + Math.floor((Number(startMonth) + numMonths) / 12);
  console.assert(
    endMonth >= 1 && endMonth <= 12,
    "getNumMonthsQueryString(): endMonth must be between 1 and 12.",
  );
  console.assert(
    endYear >= 1900 && endYear <= 2200,
    "getNumMonthsQueryString(): endYear must be between 1900 and 2200.",
  );
  const end = "" + endYear + "-" + endMonth.toString().padStart(2, "0") + "-01";
  console.log("getNumMonthsQueryString()");
  console.log("_gte_" + start + "__and___lt_" + end);
  return "_gte_" + start + "__and___lt_" + end;
}

export function get1MonthQueryString(startMonth, startYear) {
  return getNumMonthsQueryString(startMonth, startYear, 1);
}

export function getCurrentMonthQueryString() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  return get1MonthQueryString(currentMonth, currentYear);
}

// current month counts as 1 month.
// Eg. if numMonths is 3, then it's for this month and the 2 previous months
export function getLastNumMonthsQueryString(numMonths) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();
  let startMonth = currentMonth - numMonths + 1;
  let startYear = currentYear;
  while (startMonth <= 0) {
    startMonth += 12;
    startYear -= 1;
  }
  return getNumMonthsQueryString(startMonth, startYear, numMonths);
}
