import { useState, useEffect, useMemo } from "react";
import useExpenseData from "../ActiveTracker/useExpenseData";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";

import { getLastNumMonthsQueryString } from "../../functions/timeDate";
import backend from "../../functions/backend";

import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { TopNavBarPersonal } from "../../components/PageComponents/TopNavBar";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";

import { PageTitle, BodyText } from "../../styles/styledPageComponents";
import "../../styles/IPadLook.css";
import { Screen } from "../../styles/styledScreen";
import { Table } from "antd";

import { createColumnsCat } from "../ActiveTracker/tableColumns";

export default function IncomePage() {
  const [combined, setCombined] = useState([]);
  const checkResponseFail = useCheckResponseFail();

  const { formatForTable } = useExpenseData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const columnsCat = useMemo(() => createColumnsCat(editRow), []);

  useEffect(() => {
    // 101 is income // TODO: this should go in a constant
    getCurrentCategoryExpenses(101);
  }, []);

  function editRow() {}

  async function getCurrentCategoryExpenses(Category) {
    console.log("getCurrentCategoryExpenses for category: Income");
    let query =
      "/v1/tx/transaction?posted_date=" +
      getLastNumMonthsQueryString(6) +
      "&_sort_by=posted_date&_sort_order=desc";

    query += "&tx_category_id=" + Category;

    console.log("query: " + query);
    const response = await backend.get(query);
    if (
      checkResponseFail(
        response,
        "Failed to GET current category transactions:",
      )
    ) {
      return;
    }

    console.log("Got " + response.items.length + " income transactions.");

    const tableData = formatForTable(response.items);

    setCombined(tableData);
  }

  return (
    <Screen>
      <MoneyFlowHeader />
      <ErrorNoticeBar />
      <TopNavBarPersonal />
      <PageTitle>Income</PageTitle>
      <BodyText>
        Please double check that all items listed below are income, and not
        actually refunds.
      </BodyText>
      <Table dataSource={[...combined]} columns={columnsCat} />
      <BottomNavBar />
    </Screen>
  );
}
