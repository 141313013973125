import { useState, useRef, useEffect } from "react";

import styled from "styled-components";

function TableActionBar() {
  return (
    <MainContainer>
      <p className="expense">Expenses</p>
      <SearchSortContainer>
        <Search>
          <input placeholder="Search" />
        </Search>
        <p className="sort-by">Sort By</p>
      </SearchSortContainer>
    </MainContainer>
  );
}

export default TableActionBar;

const MainContainer = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  justify-content: space-between;
  .expense {
    font-size: 1.5em;
    margin-left: 20px;
    margin-top: 10px;
    color: #7a7a7a;
  }
  .sort-by {
    font-size: 1em;
    margin-right: 10px;
  }
`;

const Search = styled.div`
  border-radius: 20px;
  background-color: #f2f5f3;
  border: 2px solid #d9d9d9;
  width: 35%;
  max-width: 150px;
  height: 2em;
  margin-right: 10px;
  input {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: #f2f5f3;
    text-align: center;
    &:focus {
      outline: none;
    }
  }
`;

const SearchSortContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
`;
