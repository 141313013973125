import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useSettings, updateSetupStep1 } from "../../state/SettingsProvider";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import ProgressBar from "../../components/UI/ProgressBar";
import { SimpleFormFooter } from "../../components/UI/FormFooter";
import { PageTitle, BodyText } from "../../styles/styledPageComponents";
//import styled from "styled-components";

export default function ExpenseReviewInfoDisplay() {
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const { wheelSettings, dispatchSettings } = useSettings();

  useEffect(() => {
    console.log("ExpenseReview: InfoDisplay");
    // Initial Expense Review
    updateSetupStep1(
      wheelSettings.SETUP_STEP_EXPENSE_REVIEW_INFO,
      currentUser.money_wheel_settings_id,
      dispatchSettings,
    );
  }, []); // run only once

  function previousPage() {
    //navigate("/");   // should we go back to Plaid?  probably not.
  }

  function onFinish() {
    updateSetupStep1(
      wheelSettings.SETUP_STEP_EXPENSE_REVIEW_TOUR,
      currentUser.money_wheel_settings_id,
      dispatchSettings,
    );
    console.log(`navigating to Expense Review Tour page`);
    navigate("/expense-review-tour");
  }

  const count = 10,
    numPages = 12; // temp
  return (
    <div className="screen">
      <MoneyFlowHeader />
      <ProgressBar progress={Math.floor((count * 100) / numPages)} />

      <div className="tracker-form-split"></div>
      <div className={"form-wrapper"}>
        <PageTitle>Awesome!</PageTitle>
        <BodyText>
          Your account connection was successful. Let's sort a few of your
          recent expenses.
        </BodyText>
        <SimpleFormFooter
          onPrevButton={(e) => previousPage(e)}
          onNextButton={(e) => onFinish(e)}
          disabledPrevButton={true}
          disabledNextButton={false}
        />
      </div>
    </div>
  );
}

//const Text2 = styled.div`
//font-family: TitleFont;
//font-size: 1.25rem; /* 20/16 */
//font-weight: 600;
//line-height: 2.5625rem; /* 41/16 */
//`;
