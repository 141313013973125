export function hide(id) {
  let dom = document.getElementById(id);
  if (dom) {
    dom.style.display = "none";
  }
  dom = null;
}

export function show(id) {
  let dom = document.getElementById(id);
  if (dom) {
    dom.style.display = "block";
  }
  dom = null;
}
