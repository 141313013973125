//import busCategoryTitleIcon from "../../../assets/img/MoneyWheel/money-bus-icon.svg";
import { ReactComponent as carCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-car-icon.svg";
import { ReactComponent as entertainmentCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-entertainment-icon.svg";
import { ReactComponent as groceryCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-food-icon.svg";
import { ReactComponent as healthCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-health-icon.svg";
import { ReactComponent as homeCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-home-icon.svg";
//import marriedCategoryTitleIcon from "../../../assets/img/MoneyWheel/money-couple-icon.svg";
import { ReactComponent as otherCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-other-icon.svg";
import { ReactComponent as singlePersonCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-single-icon.svg";
import { ReactComponent as utilitiesCategoryTitleIcon } from "../../../assets/img/MoneyWheel/money-utility-icon.svg";

const categoryData = {
  Family: {
    categoryTitleIcon: singlePersonCategoryTitleIcon,
    categoryTitleText: "Personal",
    subcategoryText: [
      "Personal Item",
      "Clothing",
      "Personal Care",
      "Child Care, Pet",
      "Education",
      "Gift, Tithe, Charity",
    ],
  },
  Food: {
    categoryTitleIcon: groceryCategoryTitleIcon,
    categoryTitleText: "Food",
    subcategoryText: [
      "Grocery",
      "Restaurant",
      "Coffee Shop",
      "Liquor Store",
      "Convenience Store",
      "Meal Service",
    ],
  },
  "Home & Garden": {
    categoryTitleIcon: homeCategoryTitleIcon,
    categoryTitleText: "Home",
    subcategoryText: [
      "Mortgage, Rent",
      "Tax, Deposit",
      "Home/Rent Insurance",
      "Household",
      "Furniture, Appliance",
      "Garden, Patio",
    ],
  },
  Entertainment: {
    categoryTitleIcon: entertainmentCategoryTitleIcon,
    categoryTitleText: "Entertainment",
    subcategoryText: [
      "ATM Withdrawal",
      "Theatre, Event",
      "TV, Streaming",
      "Night Club, Concert",
      "Gaming, Music",
      "Vacation, Party",
    ],
  },
  Health: {
    categoryTitleIcon: healthCategoryTitleIcon,
    categoryTitleText: "Health",
    subcategoryText: [
      "Health Insurance",
      "Medical Bill",
      "Medical Equipment",
      "Prescription",
      "Medicine, Supplement",
      "Gym, Health Member",
    ],
  },
  Transportation: {
    categoryTitleIcon: carCategoryTitleIcon,
    categoryTitleText: "Transportation",
    subcategoryText: [
      "Loan, Insurance, Tax",
      "Gasoline, Charging",
      "Parking, Toll",
      "Repair, Maintenance",
      "Public Transport",
      "Ride Service",
    ],
  },
  Utility: {
    categoryTitleIcon: utilitiesCategoryTitleIcon,
    categoryTitleText: "Utility",
    subcategoryText: [
      "Heat Fuel, Electric",
      "Water, Sewer",
      "Trash, Recycling",
      "Internet",
      "Phone",
      "Computer",
    ],
  },
  Other: {
    categoryTitleIcon: otherCategoryTitleIcon,
    categoryTitleText: "Other",
    subcategoryText: [
      "Uncategorized",
      "Credit Card Minimum",
      "Other Loan Minimum",
      "Life Insurance",
      "Legal, Bank Fee",
      "Work Related",
    ],
  },
};

export default categoryData;
