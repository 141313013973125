import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "../../state/UserProvider";
import {
  useSettings,
  serverSetSetupComplete,
} from "../../state/SettingsProvider";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import FormFooter from "../../components/UI/FormFooter";
import addNotification from "react-push-notification";
import { getMoneyFlowToken } from "../../state/stateApplication";

import { PageTitle, BodyText } from "../../styles/styledPageComponents";
import { Form } from "antd";
import "../../styles/IPadLook.css";

import { ReactComponent as CelebrateIcon } from "../../assets/img/Other/celebrate-icon.svg";
import styled from "styled-components";

function Congratulations() {
  const { currentUser } = useUser();

  console.log(
    "Congratulations: 0: This is from the UserProvider: currentUser=",
    currentUser,
  );
  const { wheelSettings, dispatchSettings } = useSettings();
  console.log(
    "Congratulations: 0: This is from the SettingsProvider: wheelSettings=",
    wheelSettings,
  );

  const navigate = useNavigate();
  const [form1] = Form.useForm();

  useEffect(() => {
    addNotification({
      title: "Please allow notifications",
      subtitle: "Please allow notifications",
      message: "Thanks for allowing notifications",
      theme: "darkblue",
      native: true, // when using native, your OS will handle theming.
    });
  }, []); // run only during first render

  useEffect(() => {
    console.log("Congratulations: cash_settings.is_setup_complete set to true");
    serverSetSetupComplete(currentUser.money_wheel_settings_id);
    dispatchSettings({ type: "SETUP_COMPLETE" });
  }, []); // run only during first render

  function onFinish(values) {
    if (getMoneyFlowToken() === "") {
      navigate("/");
    } else {
      navigate("/money-wheel");
    }
  }

  return (
    <div className="screen">
      <MoneyFlowHeader />
      <div className="tracker-form-split">
        <div className="transactionsContainer">
          <div className={"form-wrapper"}>
            <Form
              layout="vertical"
              name="income_setup"
              onFinish={onFinish}
              form={form1}
            >
              <PageTitle>Congratulations!</PageTitle>
              <StyledCelebrateIconContainer>
                <CelebrateIcon />
                <CelebrateIcon />
                <CelebrateIcon />
                <CelebrateIcon />
                <CelebrateIcon />
                <CelebrateIcon />
              </StyledCelebrateIconContainer>
              <BodyText>
                Setup is complete. Let's go to your Money Wheel.
                <br />
                If you see a popup asking you to allow notifications, please do
                so we can let you know when you have something new.
              </BodyText>
              <FormFooter onNextButton="enabled" disabledPrevButton={true} />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Congratulations;

const StyledCelebrateIconContainer = styled.div`
  display: flex;
  width: 95%;
  margin: auto;
`;
