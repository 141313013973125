import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppProvider from "./state/AppProvider";
import PollingProvider from "./pages/ActiveTracker/PollingProvider";
import App from "./App";

import "./globals.css";
import "./styleguide.css";
import "./styles/Components.css";

const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <AppProvider>
      <PollingProvider>
        <App />
      </PollingProvider>
    </AppProvider>
  </BrowserRouter>,
);
