import { Button } from "antd";
import styled, { css } from "styled-components";

export default function FormFooter({
  onPrevButton,
  onNextButton,
  disabledPrevButton = false,
}) {
  let nextBtn;
  let prevBtn = (
    <BackButton
      value="prevous"
      className="actionbtn"
      onClick={onPrevButton ? onPrevButton : undefined}
      disabled={disabledPrevButton}
    >
      Back
    </BackButton>
  );
  if (onNextButton === "disabled") {
    nextBtn = (
      <NextButton value="next" className="actionbtn" htmlType="submit" disabled>
        Next
      </NextButton>
    );
  } else if (onNextButton && onNextButton !== "enabled") {
    // for subcat pages or expense review
    nextBtn = (
      <NextButton
        value="next"
        className="actionbtn"
        onClick={onNextButton}
        $primary
      >
        Next
      </NextButton>
    );
  } else {
    nextBtn = (
      <NextButton
        type="primary"
        value="next"
        className="actionbtn"
        htmlType="submit"
        $primary
      >
        Next
      </NextButton>
    );
  }

  return (
    <FormFooter1>
      {prevBtn}
      {nextBtn}
    </FormFooter1>
  );
}

export function SimpleFormFooter({
  onPrevButton,
  onNextButton,
  disabledNextButton = false,
  disabledPrevButton = false,
  nextBtnText = "Next",
}) {
  return (
    <FormFooter1>
      <BackButton
        className="actionbtn"
        onClick={onPrevButton}
        disabled={disabledPrevButton}
      >
        Back
      </BackButton>
      <NextButton
        type="primary"
        className="actionbtn"
        $primary
        onClick={onNextButton}
        disabled={disabledNextButton}
      >
        {nextBtnText}
      </NextButton>
    </FormFooter1>
  );
}
const FormFooter1 = styled.div`
  position: fixed;
  bottom: 0.5em;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  /* max-width: 1500px;*/ /* for responsive desktops */
  max-width: 500px; /*  for mobile-look on desktop */
  text-align: center;
  padding: 0 var(--padding-m);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--padding-m);
  padding-bottom: var(--padding-s);
  align-self: stretch;
  gap: 1em;
  @media (min-width: 768px) {
    /* changes for ipad/tablet sizes */
    width: 92%;
    gap: 4%;
    bottom: 2%;
    padding: 0;
  }
`;

const BaseButton = styled(Button)`
  min-width: 84px;
  width: 50%;
  height: 40px;
  /* on mobile height is 4.7vh = 40px/844px */

  /* for responsive ipad */
  /*
  @media (min-width: 768px) {
    height: 9vh;
  }
  */

  /* for mobile look on ipad */
  @media (min-width: 500px) {
    height: 5vh;
  }
  padding: 0;
  border-radius: 0.5em;
  border-color: #333333;

  /* font-family: CondensedFont; */
  font-size: 1rem;
  color: #333333;
`;

const BackButton = styled(BaseButton)`
  &:disabled {
    color: #989799;
    background-color: #e4e4e4;
    border-color: #f5f5f5;
  }
`;

const NextButton = styled(BaseButton)`
  ${(props) =>
    props.$primary &&
    css`
      color: white;
      background: #4f81b4 !important;
      border-color: #dddddd;
    `}

  &:disabled {
    color: #989799;
    background-color: #e4e4e4; /* a little too dark #d9d9d9;  too dark #1f1f1f; */
    border-color: #f5f5f5; /* too dark #333333 */
  }
`;
