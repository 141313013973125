import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

const { Content } = Layout;

export default function ConfirmRegistrationContent() {
  return (
    <Content>
      <h1>Admin Dashboard</h1>
      <a href="/admin-notifications">Manage Notifications</a>
      <br />
      <a href="/admin-users">Manage Users</a>
    </Content>
  );
}
