import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useSettings, updateSetupStep1 } from "../../state/SettingsProvider";
import useExpenseData from "../ActiveTracker/useExpenseData";
import useDraggableExpense from "../ActiveTracker/useDraggableExpense";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { Form, Table } from "antd";

import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import ProgressBar from "../../components/UI/ProgressBar";
import MoneyWheel from "../Tracker/MoneyWheel";
import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import ExpenseDetailsModal, {
  ExpenseDetailsCircleModal,
} from "../ActiveTracker/Modals/ExpenseDetailsModal";
import Drawer from "../ActiveTracker/Drawer";

import { ID_LIST } from "../ActiveTracker/constants";
import { createColumnsCat } from "../ActiveTracker/tableColumns";
import { get1MonthQueryString } from "../../functions/timeDate";

import { ExpenseReviewForm } from "./Forms/ExpenseReviewForm";
import InitTrackerConfig from "./InitTrackerConfig";
import { getMoneyFlowToken } from "../../state/stateApplication";
import backend from "../../functions/backend";
import { getLastNumMonthsQueryString } from "../../functions/timeDate";

import "../../styles/IPadLook.css";
import styled from "styled-components";

var currentDate = new Date();
var currentMonth = 1;
var currentYear = 1;

function ExpenseReview() {
  const navigate = useNavigate();
  const { currentUser } = useUser();
  const { wheelSettings, dispatchSettings } = useSettings();
  const { formatForTable } = useExpenseData();
  const checkResponseFail = useCheckResponseFail();
  const [cat, setCat] = useState(8);
  const [expenseData, setExpenseData] = useState([]);
  const [expenseTableFlag, setExpenseTableFlag] = useState(true);
  const [maxSplit, setMaxSplit] = useState({});
  const [editID, setEditID] = useState(0);
  const { handleTableChange, draggableExpense } =
    useDraggableExpense(expenseData);

  const [form2] = Form.useForm();
  const [form4] = Form.useForm();
  const [isModalOpenDetails, setIsModalOpenDetails] = useState(false);
  const [isModalOpenDetailsCircle, setIsModalOpenDetailsCircle] =
    useState(false);
  const [combined, setCombined] = useState([]);
  const [drawerLocation, setDrawerLocation] = useState(0);
  const [mb, setMb] = useState("1px");
  const [isPageLayoutComplete, setIsPageLayoutComplete] = useState(false);

  const [isTour, setIsTour] = useState(
    wheelSettings.setup_step === wheelSettings.SETUP_STEP_EXPENSE_REVIEW_TOUR,
  );

  useEffect(() => {
    setIsTour(
      wheelSettings.setup_step === wheelSettings.SETUP_STEP_EXPENSE_REVIEW_TOUR,
    );
  }, [wheelSettings.setup_step]);

  function setCurrentDate() {
    currentDate = new Date();
    currentMonth = currentDate.getMonth() + 1;
    currentYear = currentDate.getFullYear();
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  //const columnsCat = useMemo(() => createColumnsCat(editRow), []);

  // Sets the form fields for the Expense Details modal.
  // Opens the modal.
  // IN: id: the id of the expense
  // IN: isCallerTable is true when invoked from a row in the table
  //     isCallerTable is false when invoked from the circle modal
  async function editRow(id, isCallerTable = true) {
    setEditID(id);
    let query = "/v1/tx/transaction?id=" + id;
    const response = await backend.get(query);

    let label = response.items[0].label;
    if (!label) label = response.items[0].counterparty;
    setMaxSplit(parseFloat(response.items[0].amount));
    const values = {
      splitAmount: parseFloat(response.items[0].amount),
      label: label,
    };
    if (isCallerTable) {
      // handle additional form field initialization
      // for the modal for editing the table row
      values.category = response.items[0].tx_category_id;
      setIsModalOpenDetails(true);
      form2.setFieldsValue(values);
    } else {
      setIsModalOpenDetailsCircle(true);
      form4.setFieldsValue(values);
    }
  }

  async function handleSplit(values) {
    let splitParams = {
      original_tx_id: editID,
      new_amount: String(values.splitAmount),
    };

    if (values.category) {
      splitParams.new_tx_category_id = values.category;
      splitParams.new_tx_subcategory_id = values.category * 100;
    }

    if (values.label) {
      splitParams.new_label = values.label;
    }

    const querySplit = "/v1/tx/split";
    const responseSplit = await backend.post(querySplit, splitParams);
    checkResponseFail(responseSplit, "Failed to split transaction:");
  }

  async function onFinishEditDetails(values) {
    // necessary for modals that don't have a category field
    if (values.category === undefined) values.category = 0;
    const query = "/v1/tx/transaction?id=" + editID;
    const response = await backend.get(query);
    if (parseFloat(response.items[0].amount) !== values.splitAmount) {
      await handleSplit(values);
    } else {
      let data = {
        id: editID,
        tx_category_id: values.category,
        tx_subcategory_id: values.category * 100,
        label: values.label,
      };
      if (values.category) {
        data.categorization_type = "user_manual";
      }
      console.log("data1:", data);
      const r1 = await backend.patch("/v1/tx/transaction", data);
      checkResponseFail(r1, "Failed to PATCH transaction:");
    }
    getExpenses();
    getCurrentCategoryExpenses(cat);
    setIsModalOpenDetails(false);
    setIsModalOpenDetailsCircle(false);
  }

  const switchCategory = (name, num) => {
    if (num) {
      setExpenseTableFlag(false);
      getCurrentCategoryExpenses(num);
      setCat(num);
    }
  };

  function navBack() {
    setExpenseTableFlag(true);
  }

  /*
  async function getAllExpensesDebug() {
    console.log("getAllExpensesDebug(): ");
    let response = await backend.get(
      "/v1/tx/transaction");
    checkResponseFail(response, "Failed to GET getAllExpensesDebug transactions:");
    console.log(response.items);
  }
  */

  //const MAX_UNCATEGORIZED_TRANSACTIONS = 15;
  const MAX_UNCATEGORIZED_TRANSACTIONS = 5; // for testing

  // Gets the most recent uncategorized expenses, up to
  // MAX_UNCATEGORIZED_TRANSACTIONS, from the last 2 months.
  // Stores the data in local ExpenseData and React state expenseData.
  // expenseData only has uncategorized expenses.
  // If there a 0 expenses, goes to Congratulations page.
  async function getExpenses() {
    console.log("getExpenses for " + currentYear + "-" + currentMonth);
    const response = await backend.get(
      "/v1/tx/transaction?categorization_type=uncategorized" +
        "&_limit=" +
        MAX_UNCATEGORIZED_TRANSACTIONS +
        "&_sort_by=posted_date&_sort_order=desc&posted_date=" +
        getLastNumMonthsQueryString(2),
    );
    // FYI: to trigger error msg, set categorization_type=foo
    if (
      checkResponseFail(response, "Failed to GET uncategorized transactions:")
    )
      return null;

    const ExpenseData = formatForTable(response.items);
    setExpenseData(ExpenseData);
    if (ExpenseData.length === 0) {
      console.log("getExpenses(): there are no expenses.");
      if (window.location.pathname.includes("/expense-review")) {
        navigate("/congratulations");
      }
    }
  }

  async function getCurrentCategoryExpenses(Category) {
    console.log("getCurrentCategoryExpenses for category: " + Category);
    let query =
      "/v1/tx/transaction?posted_date=" +
      get1MonthQueryString(currentMonth, currentYear) +
      "&_sort_by=posted_date&_sort_order=desc&tx_category_id=" +
      Category;
    console.log("query: " + query);
    const response = await backend.get(query);
    if (
      checkResponseFail(
        response,
        "Failed to GET current category transactions:",
      )
    )
      return null;

    const tableData = formatForTable(response.items);
    setCombined(tableData);
  }

  useEffect(() => {
    setCurrentDate();
    console.log("First time running ExpenseReview");
    //getAllExpensesDebug();
    getExpenses();
    setIsPageLayoutComplete(true);
  }, []);

  useEffect(() => {
    if (!isTour) {
      console.log("useEffect().  isTour is false");
      if (getMoneyFlowToken() === "") {
        navigate("/");
      } else {
        updateSetupStep1(
          wheelSettings.SETUP_STEP_EXPENSE_REVIEW_SORT,
          currentUser.money_wheel_settings_id,
          dispatchSettings,
        );
      }
    }
    // hide the drawer during the tour
    setDrawerLocation(isTour ? -1 : 0);
  }, [isTour]);

  useEffect(() => {
    console.log("rerendering ExpenseReview");
  }, [expenseData]);

  const trackerConfig = InitTrackerConfig();

  async function callbackDropExpense(expense, category, array = expenseData) {
    if (expense) {
      console.log("callbackDropExpense", expense, category, array);

      let data = {
        id: expense.key,
        tx_category_id: ID_LIST[category],
        categorization_type: "user_manual",
        tx_subcategory_id: parseInt(ID_LIST[category]) * 100,
      };
      const response = await backend.patch("/v1/tx/transaction", data);
      checkResponseFail(response, "Failed to PATCH transaction:");

      const newExpenseData = array.filter((item) => item.id !== expense.id);

      // NOTE: array[0] is undefined, after dragging the last uncategorized
      // expense into a category.
      setEditID(array[0] ? array[0].key : 0);
      setExpenseData(newExpenseData);

      // FYI: setExpenseData is done asynchronously, so the log below may not be
      // accurate yet.
      console.log("EXPENSE ARRAY: ", expenseData);

      // ActiveTracker does this, but don't need it for initial ExpenseReview
      // because remaining amounts are not displayed in the wheel here.
      //getBudget();

      if (newExpenseData.length === 0) {
        console.log("All expenses categorized");
        console.log("callbackDropExpense(): there are no expenses.");
        if (window.location.pathname.includes("/expense-review")) {
          navigate("/congratulations");
        }
      }
      //this makes it so that drawer will display the new expense when it is dragged in
      if (ID_LIST[category] === cat) {
        console.log("refreshing category expenses");
        console.log("category: ", category);
        getCurrentCategoryExpenses(cat);
      }
    }
  }

  const countA = 11,
    numPages = 12; // temp
  return (
    <div className="screen">
      <MoneyFlowHeader />
      <ProgressBar progress={Math.floor((countA * 100) / numPages)} />
      <ErrorNoticeBar />
      <div className="tracker-form-split">
        <MoneyWheel
          dropExpense={callbackDropExpense}
          draggableExpense={draggableExpense}
          config={trackerConfig}
          switchCategory={switchCategory}
          showNumbers={false}
          categoryFigures={{}}
          isTour={isTour}
          setIsTour={setIsTour}
          navBack={navBack}
          isExpenseReview={true}
          wheelType={"MoneyWheel"}
          editID={editID}
          EditFunction={editRow}
        />
        <ExpenseReviewForm expenseCount={expenseData.length} />
      </div>

      <ExpenseDetailsModal
        form2={form2}
        isOpen={isModalOpenDetails}
        setIsOpen={setIsModalOpenDetails}
        onFinish={onFinishEditDetails}
        maxSplit={maxSplit}
      />
      <ExpenseDetailsCircleModal
        form4={form4}
        isOpen={isModalOpenDetailsCircle}
        setIsOpen={setIsModalOpenDetailsCircle}
        onFinish={onFinishEditDetails}
        maxSplit={maxSplit}
      />

      <Drawer
        {...{ drawerLocation, setMb, isPageLayoutComplete }}
        isInitialExpenseReview={true}
      >
        <div style={{ marginBottom: mb }}>
          <TransactionsContainer>
            <Table
              dataSource={expenseTableFlag ? [...expenseData] : [...combined]}
              columns={createColumnsCat(editRow)}
              onChange={handleTableChange}
            />
          </TransactionsContainer>
        </div>
      </Drawer>
    </div>
  );
}

export default ExpenseReview;

const TransactionsContainer = styled.div`
  font-family: TitleFont;
  font-size: 1rem; /* 20/16=1.25rem */
  font-weight: 700;
  line-height: 1.6875rem; /* 27/16 */
  text-align: left;
  white-space: break-spaces;
  /*
  overflow-y: scroll;
  min-height: 200px;
  max-height: 200px;
  */
  --header-height: 50px;
  .tableRowText {
    cursor: pointer;
  }
`;
