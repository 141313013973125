import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { useDisplayErrorMsg } from "../../hooks/useDisplayErrorMsg";
import backend from "../../functions/backend";
import { setMoneyFlowToken } from "../../state/stateApplication";
import { isGoodBrowser } from "../../functions/platform";
import { focusTextBoxes, setDisplay } from "../../functions/dom";
import { initUser, useUser } from "../../state/UserProvider";
import { initSettings, useSettings } from "../../state/SettingsProvider";
import { SyncOutlined } from "@ant-design/icons";

import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import { Form, Button, Input } from "antd";
import { BodyRegularText } from "../../styles/styledText";
import styled from "styled-components";
import * as SP from "../../styles/styledPageComponents";

export default function RegisterContent() {
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const { dispatchUser } = useUser();
  const { dispatchSettings } = useSettings();

  const navigate = useNavigate();
  const checkResponseFail = useCheckResponseFail();
  const { displayErrorMsg } = useDisplayErrorMsg();

  const doSomething = () => {
    /* do something like submit a form and then refresh recaptcha */
    setRefreshReCaptcha((r) => !r);
  };

  const [form0] = Form.useForm();

  window.onload = function () {};

  useEffect(() => {
    focusTextBoxes("form_registration_first_name");
    setDisplay("loading_msg", false);
    if (!isGoodBrowser()) {
      /*
      displayErrorMsg(
        "Early Access Note: Your specific device and browser combination has not yet been tested. Please let us know if you encounter any issues.",
      );
      */
    }
  }, []);

  async function onFinish(values) {
    backend.tokenTimeout.reinitTimeout();
    document.getElementsByTagName("HTML")[0].scrollTop = 0;

    values.phone_number = values.phone_number.replace(/\D/g, "");

    setDisplay("loading_msg", true);
    console.log("Success:", values, JSON.stringify(values));

    var storedValues = JSON.parse(JSON.stringify(values));
    // storedValues.password = undefined;
    // delete storedValues["password"];
    var res = await backend.post("/v1/user/signup", storedValues);
    if (checkResponseFail(res, "Failed to POST user signup:")) {
      setDisplay("loading_msg", false);
    } else {
      // clear the MF access token
      setMoneyFlowToken("");

      let data = await backend.post("/v1/user/login", {
        username: values.email_address,
        password: values.password,
      });
      if (checkResponseFail(data, "Failed to POST user login:")) {
        setDisplay("loading_msg", false);
      }

      console.log("Response data: " + JSON.stringify(data));

      if (data.token) {
        // store the MF access token
        setMoneyFlowToken(data.token);

        let user3 = await initUser();
        console.log("LoginContent: onFinish(): user3", user3);

        dispatchUser({
          type: "USER_LOGIN",
          payload: { user: user3 },
        });

        let wheelSettings = await initSettings(user3);

        dispatchSettings({
          type: "SETTINGS_INIT",
          payload: { settings: wheelSettings },
        });

        // start at the beginning for a newly registered user
        navigate(`/money-wheel-setup`, { replace: true });
      } else {
        console.log("No token: " + JSON.stringify(data));
        navigate(`/`, { replace: true });
      }
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <SP.ScrollingFormWrapper2>
      <ErrorNoticeBar />
      <div id="loading_msg">
        <LoadingIcon>
          <SyncOutlined spin />
        </LoadingIcon>
      </div>
      <Form
        form={form0}
        layout="vertical"
        name="form_registration"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <SP.FormTitle>Create a MoneyFlow Account</SP.FormTitle>
        <SP.FormItemContainer>
          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
              },
            ]}
            hasFeedback
          >
            <Input name="first_name" placeholder="First Name" />
          </Form.Item>

          <Form.Item
            name="last_name"
            rules={[
              {
                required: true,
              },
            ]}
            hasFeedback
          >
            <Input name="last_name" placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            name="email_address"
            rules={[
              {
                required: true,
                type: "email",
                max: 80,
                min: 5,
                message: "Please enter a valid email address.",
              },
            ]}
            hasFeedback
          >
            <Input
              name="email_address"
              placeholder="Email.  This will be your username to login."
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                min: 8,
                max: 80,
                message:
                  "Please enter a password that is at least 8 characters.",
              },
            ]}
            hasFeedback
          >
            <Input.Password name="password" placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="phone_number"
            rules={[
              {
                min: 5,
                max: 80,
                required: true,
                message: "Please enter your phone number.",
              } /*
              {
                message: "numbers only for phone number. ",
                validator: (_, value) => {

                 if (/^[0-9]+$/.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Some message here");
                  }
                },
              },*/,
            ]}
            hasFeedback
          >
            <Input name="phone_number" placeholder="Phone Number" />
          </Form.Item>
          <Text1>
            We are asking for your phone number to verify you are a real person.
            We won't spam you.
          </Text1>
          <Text1>
            By creating your account, you agree to our{" "}
            <a href="/terms">terms of service</a> and our{" "}
            <a href="/privacy-policy">privacy policy.</a>
          </Text1>
          <div>
            <Form.Item>
              <Button type="primary" htmlType="submit" size="large">
                Create Account
              </Button>
            </Form.Item>
          </div>
        </SP.FormItemContainer>
        <LoginText>
          <a href="/login">Already have an account? Login.</a>
        </LoginText>
      </Form>
    </SP.ScrollingFormWrapper2>
  );
}

const LoginText = styled.p`
  font-family: PrimaryVariableFont, TitleFont, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 2em;
`;

const Text1 = styled.div`
  ${BodyRegularText}
  text-align: left;
  margin-bottom: 1em;
`;

const LoadingIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
  font-size: 7rem;
`;
