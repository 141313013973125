import styled from "styled-components";

export default function BudgetProgressTab({
  budget,
  spent,
  remaining,
  editIcon,
}) {
  const textColor = remaining > 0 ? "#79bd47" : "#d63a42";
  const textMessage = remaining > 0 ? "Good" : "Over";
  return (
    <div>
      <TopRow>
        <FigureContainer color={textColor}>
          <span className="number">{textMessage}</span>
        </FigureContainer>
      </TopRow>

      <BudgetProgressStyle>
        <FigureContainer>
          <span className="sideText grey">
            $<span id="budget_number">{budget}</span>
          </span>
          <div>
            <span className="grey"> Planned </span>
            {editIcon}
          </div>
        </FigureContainer>
        <FigureContainer color={textColor}>
          <span className="number">
            $<span id="spent_number">{spent}</span>
          </span>{" "}
          <div> Spent </div>
        </FigureContainer>
        <FigureContainer>
          <span className="sideText grey">
            $<span id="remaining_number">{remaining}</span>
          </span>
          <div className="grey"> Remaining </div>
        </FigureContainer>
      </BudgetProgressStyle>
    </div>
  );
}

const BudgetProgressStyle = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;

  padding-bottom: 1em;
  margin-right: 32px;
  margin-left: 32px;
`;
const TopRow = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 0em;
  margin-right: 32px;
  margin-left: 32px;
  margin-bottom: -10px;
`;
const FigureContainer = styled.div`
  display: inline-block;
  max-width: 10em;
  // width: 79px;
  font-weight: bold;
  color: ${(props) => props.color && props.color};
  .green {
    color: #79bd47;
  }
  .grey {
    color: #b7b7b7;
  }
  .number {
    font-size: 25pt;
    font-weight: bold;
  }
  .sideText {
    font-size: 18pt;
    font-weight: bold;
  }
`;
