import styled from "styled-components";

const Footer = styled.div``;

const FooterContent = styled.div`
  text-align: center;
  margin: auto;
`;

const Copyright = styled.div`
  /* © 2024 MoneyFlow Organization, Inc. */
`;

/* XXX Is this inefficient to require this in every footer? Is there a better way to get the version? */
const packageJson = require("../../../package.json");
const packageJsonVersion = packageJson.version;

function MoneyFlowFooter() {
  const d = new Date();
  let year = d.getFullYear();

  return (
    <Footer>
      <FooterContent>
        <Copyright>
          Version {packageJsonVersion}&nbsp;&nbsp; © {year} MoneyFlow
          Organization, Inc.
        </Copyright>
      </FooterContent>
    </Footer>
  );
}

export default MoneyFlowFooter;
