import { SimpleFormFooter } from "../../components/UI/FormFooter";
import * as S from "./Forms/styledFormComponents";
import styled from "styled-components";

export const InfoDisplay = ({ category, onFinish, previousPage }) => {
  return (
    <div className={"form-wrapper"}>
      <S.InfoTitle>Let's personalize your MoneyFlow experience!</S.InfoTitle>

      <Text2>
        A few simple questions will help us tailor the app just for you. Don't
        worry, your info is safe with us.
      </Text2>
      <Text3>Ready to get started?</Text3>
      <SimpleFormFooter
        onPrevButton={null}
        onNextButton={(e) => onFinish(e)}
        disabledPrevButton={true}
      />
    </div>
  );
};

const Text2 = styled.div`
  font-family: PrimaryVariableFont;
  font-size: 1.25rem; /* 20/16 */
  font-weight: 400;
  line-height: 1.6875rem; /* 27/16 */
  text-align: center;
`;

const Text3 = styled.div`
  font-family: PrimaryVariableFont;
  font-size: 1.25rem; /* 20/16 */
  font-weight: 800;
  line-height: 1.6875rem; /* 27/16 */
  text-align: center;
`;
