import { useState, useRef, useEffect } from "react";

import styled from "styled-components";

import TableActionBar from "./TableActionBar";
import TableEntry from "./TableEntry";

function DrawerTable(props) {
  console.log("DrawerTable props: ");
  console.log(props.tableData);

  const tableEntries = props.tableData.map((entry) => (
    <TableEntry
      key={entry.id}
      date={entry.date}
      amount={entry.amount}
      description={entry.description}
      category={props.category === "All" ? entry.category : null}
      editRow={props.editRow}
      id={entry.id}
    />
  ));

  return (
    <MainContainer>
      <TableActionBar />

      <TableContainer>
        {tableEntries}
        {/* <TableEntry text={"1"}/>
        <TableEntry text={"2"}/>
        <TableEntry text={"3"}/>
        <TableEntry text={"4"}/>
        <TableEntry text={"5"}/>
        <TableEntry text={"6"}/>
        <TableEntry text={"7"}/>
        <TableEntry text={"8"}/>
        <TableEntry text={"9"}/>
        <TableEntry text={"10"}/> */}
      </TableContainer>
    </MainContainer>
  );
}

export default DrawerTable;

const MainContainer = styled.div`
  flex-grow: 1;
  height: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  background-color: #f0f7ef;
  margin-top: 15px;
`;

const TableContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-bottom: 10px;
`;
