import { ReactComponent as singleIcon } from "../../assets/img/GoalWheel/goal-single-icon.svg";
import { ReactComponent as single1KidIcon } from "../../assets/img/GoalWheel/goal-single-1kid-icon.svg";
import { ReactComponent as single2KidIcon } from "../../assets/img/GoalWheel/goal-single-2kid-icon.svg";
import { ReactComponent as single3KidIcon } from "../../assets/img/GoalWheel/goal-single-3kid-icon.svg";
import { ReactComponent as single4KidIcon } from "../../assets/img/GoalWheel/goal-single-+kid-icon.svg";

import { ReactComponent as coupleIcon } from "../../assets/img/GoalWheel/goal-couple-icon.svg";
import { ReactComponent as couple1KidIcon } from "../../assets/img/GoalWheel/goal-couple-1kid-icon.svg";
import { ReactComponent as couple2KidIcon } from "../../assets/img/GoalWheel/goal-couple-2kid-icon.svg";
import { ReactComponent as couple3KidIcon } from "../../assets/img/GoalWheel/goal-couple-3kid-icon.svg";
import { ReactComponent as couple4KidIcon } from "../../assets/img/GoalWheel/goal-couple-+kid-icon.svg";

import { ReactComponent as carIcon } from "../../assets/img/GoalWheel/goal-car-icon.svg";
import { ReactComponent as cashIcon } from "../../assets/img/GoalWheel/goal-cash-icon.svg";
import { ReactComponent as getawayIcon } from "../../assets/img/GoalWheel/goal-getaway-icon.svg";
import { ReactComponent as homeIcon } from "../../assets/img/GoalWheel/goal-home-icon.svg";
import { ReactComponent as retireIcon } from "../../assets/img/GoalWheel/goal-retire-icon.svg";
import { ReactComponent as travelIcon } from "../../assets/img/GoalWheel/goal-travel-icon.svg";
//import { ReactComponent as personalIcon } from "../../assets/img/GoalWheel/goal-personal-icon.svg";

function getFamilyIcon(config) {
  const familyIcons = [
    [
      singleIcon,
      single1KidIcon,
      single2KidIcon,
      single3KidIcon,
      single4KidIcon,
    ],
    [
      coupleIcon,
      couple1KidIcon,
      couple2KidIcon,
      couple3KidIcon,
      couple4KidIcon,
    ],
  ];
  const numKids = config.family.kidAgeRanges.length;
  const familyIcon = familyIcons[+config.family.married][numKids];
  return familyIcon;
}

export default function getCategoryIcons(config) {
  const categoryIcons = {
    N_Icon: getFamilyIcon(config),
    NE_Icon: cashIcon,
    E_Icon: carIcon,
    SE_Icon: getawayIcon,
    S_Icon: retireIcon,
    SW_Icon: travelIcon,
    W_Icon: homeIcon,
    NW_Icon: coupleIcon,
  };
  return categoryIcons;
}
