export const ID_LIST = {
  Uncategorized: 0,
  Personal: 1,
  Food: 2,
  Home: 3,
  Entertainment: 4,
  Health: 5,
  Transport: 6,
  Utility: 7,
  Other: 8,
  Income: 101,
  Transfer: 201,
};

export const CAT_LIST = [
  "Uncategorized",
  "Personal",
  "Food",
  "Home",
  "Entertainment",
  "Health",
  "Transport",
  "Utility",
  "Other",
];

export const monthABV = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const monthNameLookUp = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};
