import { useRef } from "react";
import { Tour } from "antd";
import Tracker from "./Tracker";
import getCategoryIcons from "./MoneyWheelIcons";
import "./style.css";

export default function ActiveWheel(props) {
  const childRefs = useRef(null);

  let steps = [];
  if (childRefs.current) {
    // Currently, these ref1, ref2, and ref3 are shared with the
    // ExpenseReview's MoneyWheel tour.  If you need to change the ref
    // location in the Tracker, that will be used by ActiveWheel,
    // then create a new ref variable, such as ref10, here and in Tracker.
    const { ref1, ref2, ref3 } = childRefs.current;

    steps = [
      {
        title: "Money Wheel",
        description: `Your Money Wheel shows your remaining money for the month
          broken down by category with the total in the center.`,
        target: () => ref1.current,
      },
      {
        title: "See All Transactions",
        description: `Tap on the center of your Money Wheel to see all your
          transactions in the drawer.`,
        target: () => ref2.current,
      },
      {
        title: "See Category Transactions",
        description: `Tap on a category to see all transactions in that
          category.  Tap on the help icon in the bottom toolbar if you have
          any questions or want to report a bug.`,
        target: () => ref3.current,
      },
    ];
  }

  // set the default, if isTour prop is not passed to Wheel
  let isTour = props.isTour;
  console.log("ActiveWheel: isTour =", isTour);
  if (isTour === undefined) {
    isTour = false;
  }
  console.log("ActiveWheel: isTour =", isTour);

  return (
    <>
      <Tracker
        {...props}
        categoryIcons={getCategoryIcons(props.config)}
        ref={childRefs}
      />
      <Tour
        open={isTour}
        onClose={() => props.setIsTour(false)}
        steps={steps}
        disabledInteraction={true}
        closeIcon={false}
      />
    </>
  );
}
