import { useMemo } from "react";
import { useUser } from "../../../state/UserProvider";
import { Button, Modal, Form, Select, InputNumber, Input } from "antd";

function DescriptionFormItem() {
  return (
    <>
      <label htmlFor="label">Description</label>
      <Form.Item name="label">
        <Input placeholder="Description" />
      </Form.Item>
    </>
  );
}

function SplitAmountFormItem({ maxSplit }) {
  return (
    <>
      <label htmlFor="splitAmount">Split Amount</label>
      <Form.Item
        name="splitAmount"
        rules={[
          {
            required: true,
            message: "The split amount is required.",
          },
          {
            message:
              "please enter a number between 0.01 - " +
              maxSplit +
              " dollars and cents",
            validator: (_, value) => {
              if (parseFloat(value) >= 0.01 && parseFloat(value) <= maxSplit) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  "please enter a number between 0.01 - " +
                    maxSplit +
                    " dollars and cents",
                );
              }
            },
          },
        ]}
        hasFeedback
      >
        <InputNumber step={0.01} />
      </Form.Item>
    </>
  );
}

function CategoryFormItem({ isCategorized }) {
  const { currentUser } = useUser();

  const isFamily =
    currentUser.marital_status !== "single" ||
    currentUser.dependents.length > 0;

  const optionNotCategorized = useMemo(() => {
    // if already categorized, do not allow recategorizing to "Not Categorized"
    return isCategorized ? [] : [{ value: 0, label: "Not Categorized" }];
  }, [isCategorized]);

  const categoryOptions = useMemo(
    () => [
      ...optionNotCategorized,
      { value: 1, label: isFamily ? "Family" : "Personal" },
      { value: 2, label: "Food" },
      { value: 3, label: "Home" },
      { value: 4, label: "Entertainment" },
      { value: 5, label: "Health" },
      { value: 6, label: "Transportation" },
      { value: 7, label: "Utility" },
      { value: 8, label: "Other" },
      { value: 101, label: "Income" },
      { value: 201, label: "Transfer" },
    ],
    [isFamily, optionNotCategorized],
  );

  return (
    <>
      <span id="category_select">
        <label htmlFor="category">Change Category</label>
        <Form.Item name="category">
          <Select options={categoryOptions}></Select>
        </Form.Item>
      </span>
    </>
  );
}

function FormFooterButtons({ formName, handleCancel }) {
  return (
    <>
      <span style={{ display: "inline-block" }}>
        <Button onClick={handleCancel}>Cancel</Button>
      </span>
      <span style={{ display: "inline-block" }}>
        <Form.Item name="submit">
          <Button form={formName} key="submit" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </span>
    </>
  );
}

export default function ExpenseDetailsModal({
  form2,
  isOpen,
  setIsOpen,
  onFinish,
  maxSplit,
}) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      title="Expense Details"
      open={isOpen}
      onOk={closeModal}
      footer={null}
      onCancel={closeModal}
    >
      <Form
        form={form2}
        name="editDetails"
        onFinish={onFinish}
        onFinishFailed={closeModal}
      >
        <DescriptionFormItem />
        <SplitAmountFormItem maxSplit={maxSplit} />
        <CategoryFormItem
          isCategorized={form2.getFieldValue("category") !== 0}
        />
        <FormFooterButtons formName="editDetails" handleCancel={closeModal} />
      </Form>
    </Modal>
  );
}

export function ExpenseDetailsCircleModal({
  form4,
  isOpen,
  setIsOpen,
  onFinish,
  maxSplit,
}) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      title="Expense Details"
      open={isOpen}
      onOk={closeModal}
      footer={null}
      onCancel={closeModal}
    >
      <Form
        form={form4}
        name="editDetailsCircle"
        onFinish={onFinish}
        onFinishFailed={closeModal}
      >
        <DescriptionFormItem />
        <SplitAmountFormItem maxSplit={maxSplit} />
        <FormFooterButtons
          formName="editDetailsCircle"
          handleCancel={closeModal}
        />
      </Form>
    </Modal>
  );
}
