import { useUser } from "../../state/UserProvider";
import { useSettings } from "../../state/SettingsProvider";

const InitTrackerConfig = () => {
  const { currentUser } = useUser();
  const { wheelSettings } = useSettings();

  const trackerConfig = {
    family: {
      married: false,
      kids: 0,
      kidAgeRanges: [],
    },
    transportation: "public",
    draggable: true,
    clickable: true,
    showText: true,
    color: "#4F81B4",
    strokeWidth: 4,
    lastCategoryDisplayed: 7,
  };

  function adjustVehicleIcon() {
    if (currentUser.vehicles) {
      const hasCar = currentUser.vehicles.length;
      if (hasCar) {
        trackerConfig.transportation = "auto";
      } else {
        trackerConfig.transportation = "public";
      }
    }
  }

  function ageToAgeRange(age) {
    if (age < 0) {
      console.error("ERROR: age is negative");
      return 0;
    } else if (age <= 2) {
      return 0;
    } else if (age <= 5) {
      return 1;
    } else if (age <= 12) {
      return 2;
    } else if (age <= 17) {
      return 3;
    } else if (age <= 25) {
      return 4;
    } else {
      /* adult */
      return 5;
    }
  }

  function adjustFamilyIcon() {
    trackerConfig.family.married = "single" !== currentUser.marital_status;
    trackerConfig.family.kids = wheelSettings.dependent_count;
    if (currentUser.dependentAges) {
      console.assert(
        wheelSettings.dependent_count === currentUser.dependentAges.length,
        "InitTrackerConfig: dependent_count should match dependentAges array len",
      );
      trackerConfig.family.kidAgeRanges = [];
      // show at most 4 kids, for now
      const MAX_KIDS = 4;
      const NUM_KIDS_SHOWN = Math.min(
        MAX_KIDS,
        currentUser.dependentAges.length,
      );
      for (let i = 0; i < NUM_KIDS_SHOWN; i++) {
        const age = currentUser.dependentAges[i];
        trackerConfig.family.kidAgeRanges.push(ageToAgeRange(age));
      }
    }
    //console.log("adjustFamilyIcon(): kidAgeRanges =", trackerConfig.family.kidAgeRanges);
  }

  adjustFamilyIcon();
  adjustVehicleIcon();

  return trackerConfig;
};

export default InitTrackerConfig;
