import React from "react";
import { Layout } from "antd";
import AdminFooter from "./layout/AdminFooter";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import AdminTransactionsContent from "./AdminTransactionsContent";

export default function AdminPage() {
  return (
    <>
      <Layout>
        <MoneyFlowHeader />
        <AdminTransactionsContent />
        <AdminFooter />
      </Layout>
    </>
  );
}
