import { Input, InputNumber } from "antd";
import { SubheaderText, BodyRegularText } from "../../../styles/styledText";
import styled from "styled-components";

export const FormQuestion = styled.h1`
  ${SubheaderText};
  margin-bottom: 0.6rem;
`;

export const FormQuestionHelp = styled.h4`
  ${BodyRegularText};
  margin-top: 0;
`;

export const InfoTitle = FormQuestion;
export const InfoText = FormQuestionHelp;

// create a Styled Component from an Ant InputNumber component
export const StyledInputNumber = styled(InputNumber)`
  display: block;
  height: 2.4em; /* 48/20 */
  line-height: 2.4em;
  width: min(430px, 100vw - 2 * var(--padding-m));
  text-align: left;
  border-radius: 0.5em;
  color: #333333;
  padding: 0 0.875em;
  font-size: 1.25rem; /* 20/16 =1.25rem in Figma */
`;

export const StyledInput = styled(Input)`
  display: block;
  height: 2.4em; /* 48/20 */
  line-height: 2.4em;
  width: min(430px, 100vw - 2 * var(--padding-m));
  text-align: left;
  border-radius: 0.5em;
  color: #333333;
  padding: 0 0.875em;
  font-size: 1.25rem; /* 20/16 =1.25rem in Figma */
`;

export const RadioCircle = () => {
  return (
    <div
      style={{
        width: "24px",
        height: "24px",
        border: "2px solid #C7D3EB",
        borderRadius: "12px",
        backgroundColor: "white",
        marginLeft: "0",
      }}
    ></div>
  );
};

// CSS Variables for Ant Mobile Radio component
export const radioStyle = {
  "--icon-size": "24px",
  "--font-size": "20px",
  "--gap": "24px",
};

// CSS Variables for Ant Mobile Stepper component
export const stepperStyle = {
  "--border-radius": "18px",

  "--button-font-size": "40px",
  "--input-font-size": "63px",
  "--button-background-color": "white",
  "--input-background-color": "white",
  "--button-text-color": "#1684c7",
  "--input-font-color": "white",

  "--height": "94px",
};
