import categoryData from "../CFTSetup/Forms/Data";

import { ReactComponent as singleIcon } from "../../assets/img/MoneyWheel/money-single-icon.svg";
import { ReactComponent as single1KidIcon } from "../../assets/img/MoneyWheel/money-single-1kid-icon.svg";
import { ReactComponent as single2KidIcon } from "../../assets/img/MoneyWheel/money-single-2kid-icon.svg";
import { ReactComponent as single3KidIcon } from "../../assets/img/MoneyWheel/money-single-3kid-icon.svg";
import { ReactComponent as single4KidIcon } from "../../assets/img/MoneyWheel/money-single-+kid-icon.svg";

import { ReactComponent as coupleIcon } from "../../assets/img/MoneyWheel/money-couple-icon.svg";
import { ReactComponent as couple1KidIcon } from "../../assets/img/MoneyWheel/money-couple-1kid-icon.svg";
import { ReactComponent as couple2KidIcon } from "../../assets/img/MoneyWheel/money-couple-2kid-icon.svg";
import { ReactComponent as couple3KidIcon } from "../../assets/img/MoneyWheel/money-couple-3kid-icon.svg";
import { ReactComponent as couple4KidIcon } from "../../assets/img/MoneyWheel/money-couple-+kid-icon.svg";

import { ReactComponent as busIcon } from "../../assets/img/MoneyWheel/money-bus-icon.svg";
import { ReactComponent as carIcon } from "../../assets/img/MoneyWheel/money-car-icon.svg";

function getFamilyIcon(config) {
  const familyIcons = [
    [
      singleIcon,
      single1KidIcon,
      single2KidIcon,
      single3KidIcon,
      single4KidIcon,
    ],
    [
      coupleIcon,
      couple1KidIcon,
      couple2KidIcon,
      couple3KidIcon,
      couple4KidIcon,
    ],
  ];
  const numKids = config.family.kidAgeRanges.length;
  const familyIcon = familyIcons[+config.family.married][numKids];
  return familyIcon;
}

export default function getCategoryIcons(config) {
  const transportationIcon =
    config.transportation === "public" ? busIcon : carIcon;
  const categoryIcons = {
    N_Icon: getFamilyIcon(config),
    NE_Icon: categoryData["Home & Garden"].categoryTitleIcon,
    E_Icon: transportationIcon,
    SE_Icon: categoryData.Utility.categoryTitleIcon,
    S_Icon: categoryData.Other.categoryTitleIcon,
    SW_Icon: categoryData.Health.categoryTitleIcon,
    W_Icon: categoryData.Entertainment.categoryTitleIcon,
    NW_Icon: categoryData.Food.categoryTitleIcon,
  };
  return categoryIcons;
}
