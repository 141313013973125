// Custom React hook for tableData related code

import { iconRef, iconRefArray, iconStringArray } from "./icons";
import questionMarkIcon from "../../assets/img/Other/question-mark-icon.svg";

// returns the icon that is correct for this transaction
function getIcon(tx) {
  if (tx.categorization_type === "uncategorized") {
    return questionMarkIcon;
  } else if (tx.tx_category_id === 101) {
    return iconRef.income;
  } else if (parseInt(tx.tx_category_id) < iconRefArray.length) {
    return iconRefArray[parseInt(tx.tx_category_id)];
  }
  return iconRef.All;
}

// IN: oldDate: format is YYYY-MM-DD
// takes oldDate and formats it correctly for the table
// in the ExpenseReview, ActiveTracker, and Income Page's drawer.
function getDateForTable(oldDate) {
  /*  format is M/D/YY
      BUG: this is 1 day earlier than the correct day!

  const formatter = new Intl.DateTimeFormat("en-US", {
    dateStyle: "short",
  });
  const formattedDate = formatter.format(
    new Date(oldDate),
  );
  console.log(`formattedDate = ${formattedDate}`);
  console.log(`oldDate = ${oldDate}`);

  return formattedDate;
  */

  //if you just pass in the date string it will interpret it as utc
  //if you pass in separate arguments it will interpret it as local time
  // format is M/D/YYYY
  //new Date(oldDate.split("-")).toLocaleDateString();

  // format is M/D
  return new Date(oldDate.split("-")).toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
  });
}

const useExpenseData = () => {
  // IN: items: an array of tx
  // returns array of formatted tx
  const formatForTable = (items) => {
    let tableData = [];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        tableData[i] = {};
        tableData[i].id = items[i].id;
        tableData[i].key = items[i].id;

        tableData[i].amountString = String(
          parseFloat(items[i].amount).toFixed(2),
        );
        if (items[i].is_split) {
          tableData[i].amountString +=
            "\n of " + String(parseFloat(items[i].original_amount).toFixed(2));
        }
        tableData[i].amount = parseFloat(items[i].amount).toFixed(2);

        if (items[i].label) {
          tableData[i].description = items[i].label;
        } else {
          tableData[i].description = items[i].counterparty;
        }

        //setcategory for the new drawer table:
        tableData[i].category = iconStringArray[items[i].tx_category_id];

        tableData[i].date = getDateForTable(items[i].posted_date);

        // ExpenseReview's getCurrentCategoryExpenses includes this
        //tableData[i].etype = items[i].account_type;

        tableData[i].icon = getIcon(items[i]);

        tableData[i].splitFromTxId = items[i].is_split
          ? items[i].split_from_tx_id
          : -1;
      }
    }

    return tableData;
  };

  return { formatForTable };
};

export default useExpenseData;
