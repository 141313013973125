// for debugging,
// print string messages, in crazy, blinding colors.

function logRed(message) {
  console.log("%c" + message, "color: white; background-color: red");
}

function logYellow(message) {
  console.log("%c" + message, "color: black; background-color: yellow");
}

function logBlue(message) {
  console.log("%c" + message, "color: blue; background-color: azure");
}

function logPurple(message) {
  console.log("%c" + message, "color: indigo; background-color: lavender");
}

function logGreen(message) {
  console.log("%c" + message, "color: green; background-color: honeydew");
}

function logOrange(message) {
  console.log(
    "%c" + message,
    "color: darkorange; background-color: lightyellow",
  );
}

function logBrown(message) {
  console.log("%c" + message, "color: maroon; background-color: linen");
}

export { logRed, logYellow, logBlue, logPurple, logGreen, logOrange, logBrown };
