import React, { useEffect } from "react";
import { Layout, Card, Form, Button, Input } from "antd";
import { useNavigate } from "react-router-dom";
import backend from "../../functions/backend";

const { Content } = Layout;
const layout = {
  labelCol: {
    span: 16,
  },
  wrapperCol: {
    span: 12,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 0,
    span: 16,
  },
};

export default function RegisterContent() {
  const navigate = useNavigate();
  const [form0] = Form.useForm();
  window.onload = function () {
    //  form0.setFieldsValue(userfields);
  };

  function setDisplay(id, flag) {
    var domObj = document.getElementById(id);
    if (domObj) {
      if (flag) {
        domObj.style.display = "block";
      } else {
        domObj.style.display = "none";
      }
      domObj = null;
    }
  }

  useEffect(() => {
    //  form0.setFieldsValue(userfields);
    //addOwlTools();
  }, [form0]);

  const onFinish = async (values) => {
    console.log("Success:", values, JSON.stringify(values));
    // TODO: add user submit business logic
    let resp = await backend.post("/v1/user/signup", values);
    console.log(`response: ${resp}`);
    navigate(`/money-wheel-setup`, { replace: true });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //TODO: get these from API

  /* XXX initialValues={userfields} XXX duplicate attribute in Form below */
  return (
    <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
      <div className="site-layout-background" style={{ padding: 24 }}>
        <Form
          {...layout}
          form={form0}
          layout="vertical"
          name="form_registration"
          initialValues={{
            remember: true,
          }}
          onFinish={(values) => onFinish(values)}
          onFinishFailed={onFinishFailed}
        >
          <Card title="Payment Information" style={{ marginTop: "30px" }}>
            <Form.Item
              name="bankruptcy_file_number"
              label="Bankruptcy File Number"
            >
              <Input name="bankruptcy_file_number" />
            </Form.Item>
          </Card>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit Payment Information
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Content>
  );
}
