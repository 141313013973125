import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";

import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { TopNavBarPersonal } from "../../components/PageComponents/TopNavBar";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";

import * as SP from "../../styles/styledPageComponents";
import { PageTitle } from "../../styles/styledPageComponents";
import { Form, Button, Select, Input } from "antd";
import "../../styles/IPadLook.css";
import backend from "../../functions/backend";

function ProfilePage() {
  const navigate = useNavigate();

  const [form1] = Form.useForm();
  const location = useLocation();
  var [regionOptions, setRegionOptions] = useState([]);
  var [countryOptions, setCountryOptions] = useState([]);
  var [languageOptions, setLanguageOptions] = useState([]);
  var [timezoneOptions, setTimezoneOptions] = useState([]);
  const currentPage = location.state;
  // currentPage is null, if no state was passed via Link or navigate.
  const { currentUser, dispatchUser } = useUser();
  console.log("This is from the UserProvider: currentUser=", currentUser);

  const checkResponseFail = useCheckResponseFail();

  async function init() {
    let fields = {};
    if (currentUser) {
      const data = { id: currentUser.addresses };
      const response = await backend.get("/v1/address", data);
      checkResponseFail(response, "Failed to get address:");
      if (response.success) {
        if (response.items.length > 0) {
          fields.address_1 = response.items[0].line_1;
          fields.address_2 = response.items[0].line_2;
          fields.state = response.items[0].region;
          fields.city = response.items[0].city;
          fields.country = response.items[0].country_id;
          fields.zip_code = response.items[0].postal_code;
        }
      }

      fields.first_name = currentUser.first_name;
      fields.last_name = currentUser.last_name;
      fields.marital_status = currentUser.marital_status;
      fields.email_address = currentUser.email_address;
      fields.gender = currentUser.gender;
      fields.username = currentUser.username;
      fields.phone_number = currentUser.phone_number;
      fields.date_of_birth = currentUser.date_of_birth;
      fields.language = parseInt(currentUser.language_id);
      fields.timezone = parseInt(currentUser.timezone_id);
      if (currentUser.is_veteran) {
        fields.veteran_status = "veteran";
      } else {
        fields.veteran_status = "none";
      }
    }

    form1.setFieldsValue(fields);

    getOptionsList("/v1/country?is_available=true", setCountryOptions);
    getOptionsList("/v1/language?is_available=true", setLanguageOptions);
    getOptionsList("/v1/timezone?is_available=true", setTimezoneOptions);

    // country_id is a number
    // region code might be a 2 letter US State abbrv
    // region name might be the full US State name
    getOptionsList("/v1/region?country_id=" + fields.country, setRegionOptions);
  }

  async function getOptionsList(url, setterFunction) {
    var response = await backend.get(url);
    var ret = [];
    for (var x in response.items) {
      ret.push({
        label: response.items[x].name,
        value: response.items[x].id,
      });
    }
    setterFunction(ret);
    // return ret;
  }

  const handleCountryChange = (value) => {
    // update the states/ provinces / regions available for the newly selected country
    getOptionsList("/v1/region?country_id=" + value, setRegionOptions);
  };

  useEffect(() => {
    init();
  }, []);

  async function onFinish(values) {
    const userSetValues = {
      id: currentUser.id,
      first_name: values.first_name,
      last_name: values.last_name,
      username: values.username,
      marital_status: values.marital_status,
      email_address: values.email_address,
      gender: values.gender,
      phone_number: values.phone_number,
      current_password: values.current_password,
      new_password: values.new_password,
      date_of_birth: values.date_of_birth,
      language_id: parseInt(values.language),
      timezone_id: parseInt(values.timezone),
    };
    const dispatchUserSetValues = JSON.parse(JSON.stringify(userSetValues));
    dispatchUserSetValues.is_veteran = Boolean(
      values.veteran_status === "veteran",
    );

    userSetValues.military_status = values.veteran_status;

    const addressSetValue = {
      id: currentUser.addresses,
      line_1: values.address_1,
      line_2: values.address_2,
      region: values.state,
      city: values.city,
      country_id: parseInt(values.country),
      postal_code: values.zip_code,
    };

    dispatchUser({
      type: "PROFILE",
      payload: dispatchUserSetValues,
    });
    const response = await backend.patch("/v1/user", userSetValues);
    checkResponseFail(response, "Failed to patch user:");
    const responseAddress = await backend.patch("/v1/address", addressSetValue);
    checkResponseFail(responseAddress, "Failed to patch address:");

    if (!responseAddress.success) {
      console.warn("Fail: patch address.  addressSetValue =");
      console.warn(addressSetValue);
    } else {
      console.log("Success: patch address");
    }

    if (responseAddress.success && response.success) {
      navigate(currentPage ? currentPage : "/money-wheel");
    } else {
      document.getElementsByTagName("HTML")[0].scrollTop = 0;
    }
  }

  function navBack() {
    navigate(currentPage ? currentPage : "/money-wheel");
  }

  function onFinishFailed(errorInfo) {}

  return (
    <div className="screen">
      <MoneyFlowHeader />
      <ErrorNoticeBar />
      <TopNavBarPersonal />
      <div className="tracker-form-split">
        <SP.ScrollingAboveBottomNavBar>
          <Form
            style={{ maxWidth: 430, width: "100%" }}
            layout="vertical"
            name="form_registration"
            initialValues={{
              remember: true,
            }}
            form={form1}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <PageTitle>Profile</PageTitle>

            <SP.FormItemContainer>
              <Form.Item name="current_password" label="Current Password">
                <Input.Password name="current_password" />
              </Form.Item>
              <Form.Item name="new_password" label="New Password">
                <Input.Password name="new_password" />
              </Form.Item>
              <Form.Item name="first_name" label="First Name" hasFeedback>
                <Input name="first_name" />
              </Form.Item>
              <Form.Item name="last_name" label="Last Name" hasFeedback>
                <Input name="last_name" />
              </Form.Item>
              <Form.Item
                name="email_address"
                label="Email"
                rules={[
                  {
                    message:
                      "Letters, numbers, +, -, ~, dot (.) or underscore (_) only for emails.  Can not start with a number.",
                    validator: (_, value) => {
                      if (
                        !/^[0-9]+/.test(value) &&
                        /^[\-\~\+\.\@\_0-9a-zA-Z]+$/.test(value)
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
                hasFeedback
              >
                <Input name="email_address" />
              </Form.Item>
              <Form.Item
                name="username"
                label="Username"
                rules={[
                  {
                    required: true,
                    message: "The name is required.",
                  },
                  {
                    message:
                      "Lowercase letters, numbers or underscore (_) only for usernames.  Must start with a lowercase letter.",
                    validator: (_, value) => {
                      if (
                        !/^[\_0-9]/.test(value) &&
                        /^[\_0-9a-z]+$/.test(value)
                      ) {
                        return Promise.resolve();
                      } else {
                        return Promise.reject("Some message here");
                      }
                    },
                  },
                ]}
                hasFeedback
              >
                <Input name="username" />
              </Form.Item>
              <Form.Item name="phone_number" label="Phone Number" hasFeedback>
                <Input name="phone_number" />
              </Form.Item>
              <Form.Item name="date_of_birth" label="Date of Birth" hasFeedback>
                <Input name="date_of_birth" />
              </Form.Item>
              <Form.Item name="address_1" label="Address 1" hasFeedback>
                <Input name="address_1" />
              </Form.Item>
              <Form.Item name="address_2" label="Address 2" hasFeedback>
                <Input name="address_2" />
              </Form.Item>
              <Form.Item name="city" label="City" hasFeedback>
                <Input name="city" />
              </Form.Item>
              <Form.Item name="zip_code" label="Postal / Zip Code" hasFeedback>
                <Input name="zip_code" />
              </Form.Item>
              <Form.Item
                name="state"
                label="State / Province / Region"
                hasFeedback
              >
                <Select options={regionOptions}></Select>
              </Form.Item>
              <Form.Item name="country" label="Country" hasFeedback>
                <Select
                  options={countryOptions}
                  onChange={handleCountryChange}
                ></Select>
              </Form.Item>
              <Form.Item name="timezone" label="Time Zone" hasFeedback>
                <Select options={timezoneOptions}></Select>
              </Form.Item>
              <Form.Item name="language" label="Language" hasFeedback>
                <Select options={languageOptions}></Select>
              </Form.Item>
              <Form.Item
                name="marital_status"
                label="Relationship Status"
                hasFeedback
              >
                <Select>
                  <option value="unspecified">Unspecified</option>
                  <option value="single">Single</option>
                  <option value="partnered">Partnered</option>
                  <option value="married">Married</option>
                </Select>
              </Form.Item>
              <Form.Item name="gender" label="Gender" hasFeedback>
                <Select>
                  <option value="unspecified">Unspecified</option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                  <option value="nonbinary">Nonbinary</option>
                </Select>
              </Form.Item>
              <Form.Item
                name="veteran_status"
                label="Military Status"
                hasFeedback
              >
                <Select>
                  <option value="unspecified">Unspecified</option>
                  <option value="none">None</option>
                  <option value="active">Active</option>
                  <option value="veteran">Veteran</option>
                </Select>
              </Form.Item>
            </SP.FormItemContainer>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save Profile
              </Button>
            </Form.Item>
          </Form>
        </SP.ScrollingAboveBottomNavBar>
      </div>

      <BottomNavBar />
    </div>
  );
}

export default ProfilePage;
