import { createContext, useContext, useReducer } from "react";
import backend from "../functions/backend";

// settings.setup_step
//   is the *first page* the user has not yet completed in the setup process.
//   It can only increase.
//   Client needs to set this each time, they go to the next page.
//
// setup_step's value:
//   0 means the first page.
//   num_pages means all setup pages have been completed.

// This is called after the user logs in,
// because we need the user argument.
// IN: 'usr' is the user.
// IN: 'wheel' can be 'cash', 'goal', or 'debt'.
// RETURNS: the Settings object from the server + additional properties:
//    'wheel':  current wheel
//    SETUP_STEP_PLAID: 100
//    SETUP_STEP_EXPENSE_REVIEW_INFO: 101
//
// called by LoginContent and RegisterContent
export async function initSettings(usr, wheel = "cash") {
  if (usr.role === "admin") {
    return null;
  }

  // usr.money_wheel_settings_id is the id for the /v1/money_wheel_settings object for this user
  let endpoint = "/v1/money_wheel_settings?id=" + usr.money_wheel_settings_id;
  if (wheel === "goal") {
    endpoint = "/v1/goal_wheel_settings?id=" + usr.goal_settings_id;
  } else if (wheel === "debt") {
    endpoint = "/v1/credit_wheel_settings?id=" + usr.debt_settings_id;
  }

  let response = await backend.get(endpoint);

  if (!response.success) {
    console.warn("initSettings(): Failed to get settings:", response);
    alert(response.message);
    //navigate("/");
  } else {
    let settings = response.items[0];
    settings.wheel = wheel;

    // constants
    settings.SETUP_STEP_PLAID = 100;
    settings.SETUP_STEP_EXPENSE_REVIEW_INFO = 101;
    settings.SETUP_STEP_EXPENSE_REVIEW_TOUR = 102;
    settings.SETUP_STEP_EXPENSE_REVIEW_SORT = 103;
    //settings.SETUP_STEP_ACTIVE_WHEEL_INFO = 111;
    settings.SETUP_STEP_ACTIVE_WHEEL_TOUR = 112;
    settings.SETUP_STEP_ACTIVE_WHEEL_MAIN = 113;
    //settings.SETUP_STEP_CREDIT_WHEEL_INFO = 201;
    settings.SETUP_STEP_CREDIT_WHEEL_TOUR = 202;
    settings.SETUP_STEP_CREDIT_WHEEL_MAIN = 203;
    //settings.SETUP_STEP_GOALS_WHEEL_INFO = 301;
    settings.SETUP_STEP_GOALS_WHEEL_TOUR = 302;
    settings.SETUP_STEP_GOALS_WHEEL_MAIN = 303;

    console.log("initSettings(): settings =", settings);
    console.log("setup_step =", settings.setup_step);
    return settings;
  }
}

// IN: wheel: can be "cash", "goal" or "debt"
export async function serverSetPage(settings_id, setup_step, wheel = "cash") {
  let endpoint = "/v1/money_wheel_settings";
  if (wheel === "goal") {
    endpoint = "/v1/goal_wheel_settings";
  } else if (wheel === "debt") {
    endpoint = "/v1/credit_wheel_settings";
  }

  let data = { id: settings_id, setup_step: setup_step };
  let response = await backend.patch(endpoint, data);

  if (!response.success) {
    console.warn("Failed to patch " + endpoint, response);

    if (response.http_status_code === 401) {
      //navigate("/");
    } else {
      alert(response.message);
    }
  }
}

// IN: wheel: can be "cash", "goal" or "debt"
export function updateSetupStep1(
  new_setup_step,
  settings_id,
  dispatchSettings,
  wheel = "cash",
) {
  serverSetPage(settings_id, new_setup_step, wheel);
  dispatchSettings({
    type: "UPDATE_SETUP_STEP",
    payload: { setup_step: new_setup_step },
  });
}

export async function serverSetSetupComplete(settings_id) {
  let data = { id: settings_id, is_setup_complete: true };
  let response = await backend.patch("/v1/money_wheel_settings", data);

  if (!response.success) {
    console.warn("Failed to patch money_wheel_settings", response);

    if (response.http_status_code === 401) {
      //navigate("/");
    } else {
      alert(response.message);
    }
  }
}

const SettingsContext = createContext({});

function SettingsProvider({ children }) {
  // reducer should not have any side effects
  const reducer = (state, action) => {
    let settingsCopy;
    switch (action.type) {
      case "SETTINGS_INIT":
        console.log("reducer case SETTINGS_INIT");
        console.log(action.payload.settings);
        console.log("new settings");

        // Since React wants state to be immutable,
        // make a deep copy of the state value fields
        settingsCopy = structuredClone(action.payload.settings);
        return settingsCopy;

      case "UPDATE_SETUP_STEP":
        console.log(
          "SettingsProvider: Case UPDATE_SETUP_STEP.  new settings is...",
        );
        console.log({
          ...state,
          setup_step: action.payload.setup_step,
        });
        return {
          ...state,
          setup_step: action.payload.setup_step,
        };

      case "NUM_DEPENDENTS":
        return {
          ...state,
          dependent_count: action.payload.dependent_count,
        };

      case "INCOME_TAX_WITHHELD":
        return {
          ...state,
          income_tax_withheld: action.payload.income_tax_withheld,
        };

      case "SETUP_COMPLETE":
        return {
          ...state,
          is_setup_complete: true,
        };

      // Caution: this will reset SettingsProvider, but it doesn't
      // reset everything needed to start the program over
      case "DEBUG_RESET":
        return {
          ...state,
          setup_step: 0,
        };

      default:
        return state;
    }
  };
  const [wheelSettings, dispatchSettings] = useReducer(reducer, {});

  return (
    <SettingsContext.Provider value={{ wheelSettings, dispatchSettings }}>
      {children}
    </SettingsContext.Provider>
  );
}

// Define custom hook to allow access to the values
// made available by SettingsProvider.
// useContext is how you access a context value.
// So now you don't need to export SettingsContext.
export const useSettings = () => useContext(SettingsContext);

export default SettingsProvider;
