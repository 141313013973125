import * as React from "react";

const NotifyFlagContext = React.createContext({});

function NotifyFlagProvider({ children }) {
  const myloadState = (key) => {
    let serializedState = localStorage.getItem("flagValue" + key);
    if (serializedState === null) {
      return {};
    }
    //return temp[0].step;
    return JSON.parse(serializedState);
  };

  const mysaveState = (state, key) => {
    let serializedState = JSON.stringify(state);
    localStorage.setItem("flagValue" + key, serializedState);
  };
  var [flagValue, setFlagValue] = React.useState({ value: false });

  //  setCount(x);

  function initFlagValue() {
    var x = myloadState("flagValue");
    setFlagValue(x);
    return x;
  }

  function saveFlagValue() {
    var x = myloadState("flagValue");
    x.value = true;
    mysaveState(x, "flagValue");
    setFlagValue(x);
  }
  // *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  //const value = {state, dispatch}

  const value = { flagValue, initFlagValue, saveFlagValue };
  return (
    <NotifyFlagContext.Provider value={value}>
      {children}
    </NotifyFlagContext.Provider>
  );
}

export const UseNotifyFlagContext = () => React.useContext(NotifyFlagContext);
export default NotifyFlagProvider;
