import { daysSince } from "./timeDate";
import backend from "./backend";

// IN: month: 0..11
// IN: year: 4 digit year (eg 2024)
//
//returns the id that should be modified in the future if
// we need to review the previous month, otherwise returns -1
export async function getMonthReviewStatus(month, year) {
  console.log(`getMonthReviewStatus(): month=${month} year=${year}`);
  const response = await backend.get("/v1/money_wheel_settings");
  if (response.items) {
    const items = response.items[0];
    const createdDate = new Date(items.created_datetime);
    console.log(`createdDate month=${createdDate.getMonth()}`); // 0..11
    console.log(`createdDate year=${createdDate.getFullYear()}`);
    if (
      (items.last_review_month &&
        items.last_review_month === month &&
        items.last_review_year &&
        items.last_review_year === year) ||
      //or check to see if the user account was created this month,
      // therefore we won't need to review until next month
      (createdDate.getMonth() === month &&
        createdDate.getFullYear() === year) ||
      // account is less than 10 days old
      daysSince(createdDate) < 10
    ) {
      return -1; //return false if the month and year are the same as the last review
      //because this means that we have already visited during this month and year
    }
    return items.id; //otherwise return the id, meaning we need to review the previous month,
    //id will be used to update the review status in the future
  }
  return -1; //default
}

export async function updateMonthReview(month, year, id) {
  const response = await backend.patch("/v1/money_wheel_settings", {
    last_review_month: month,
    last_review_year: year,
    id: id,
  });
  console.log("555");
  console.log(response);
}
