import { Button, Modal, Form, InputNumber } from "antd";

function FormFooterButtons({ buttonText, formName, handleCancel }) {
  return (
    <>
      <span style={{ display: "inline-block" }}>
        <Button onClick={handleCancel}>Cancel</Button>
      </span>
      <span style={{ display: "inline-block" }}>
        <Form.Item name="submit">
          <Button form={formName} key="submit" htmlType="submit">
            {buttonText}
          </Button>
        </Form.Item>
      </span>
    </>
  );
}

export default function PlanChangerModal({
  form3,
  isOpen,
  setIsOpen,
  onFinish,
}) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal
      title="Change Planned Amount"
      open={isOpen}
      onOk={closeModal}
      onCancel={closeModal}
      footer={null}
    >
      <Form
        form={form3}
        name="PlannedChanger"
        onFinish={onFinish}
        onFinishFailed={(errorInfo) => {
          closeModal();
        }}
      >
        <Form.Item name="planned">
          <InputNumber />
        </Form.Item>
        <FormFooterButtons
          buttonText="Change"
          formName="PlannedChanger"
          handleCancel={closeModal}
        />
      </Form>
    </Modal>
  );
}
