import { useState, useRef, useEffect } from "react";

import DrawerHeader from "./DrawerHeader";
import DrawerTable from "./DrawerTable";

import styled from "styled-components";

const TRANSITION_DURATION = 0.25;
function Drawer(props) {
  document.body.style.overflow = "hidden"; // todo maybe a better way to achieve this
  const [drawerBounds, setDrawerBounds] = useState([0, 0]); //the short height and the tall height
  //find the bounds on the drawer
  function setCoordinatesAnchors() {
    function getMyCoordinates(id) {
      let dom = document.getElementById(id);
      let ret = 0;
      if (dom) {
        ret = dom.getBoundingClientRect();
      }
      dom = null;
      return ret;
    }
    const topOfBottomNavBar = parseInt(getMyCoordinates("bottomNavBar").top);
    const donut = parseInt(getMyCoordinates("donut").bottom);

    let bottom = window.innerHeight - topOfBottomNavBar;
    let shortTop = window.innerHeight - donut;
    console.log("shortTop: ", shortTop, " bottom: ", bottom);
    setDrawerBounds([topOfBottomNavBar, shortTop - bottom]);
  }
  useEffect(() => {
    setCoordinatesAnchors();
  }, []);

  const [drawerTall, setDrawerTall] = useState(false);
  const toggleDrawer = () => {
    console.log("toggleDrawer");
    drawerRef.current.classList.add(drawerTall ? "short" : "tall");
    drawerRef.current.classList.remove(drawerTall ? "tall" : "short");
    setDrawerTall(!drawerTall);
  };

  const drawerRef = useRef(null);
  return (
    drawerBounds[0] &&
    drawerBounds[1] && ( //only render once the bounds are set
      <DrawerContainer
        ref={drawerRef}
        className={"short"}
        tallHeight={drawerBounds[0]}
        shortHeight={drawerBounds[1]}
        duration={TRANSITION_DURATION}
      >
        <DrawerHandle>
          <DrawerKnob onClick={toggleDrawer} />
        </DrawerHandle>
        <DrawerHeader
          drawerTall={drawerTall}
          duration={TRANSITION_DURATION}
          planned={props.planned}
          remaining={props.remaining}
          spent={props.spent}
          date={props.date}
          category={props.category}
          editPlanned={props.editPlanned}
          editDate={props.editDate}
        />
        <DrawerTable
          tableData={props.tableData}
          category={props.category}
          editRow={props.editRow}
        />
      </DrawerContainer>
    )
  );
}

export default Drawer;

const DrawerContainer = styled.div`
  background-color: white;
  width: 100vw;
  max-width: 760px;
  height: 1000px;
  z-index: 1200;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  filter: drop-shadow(0px 0px 4px grey);
  transition:
    height ${(props) => props.duration}s,
    top ${(props) => props.duration}s;
  &.short {
    height: ${(props) => props.shortHeight}px;
    top: ${(props) => props.tallHeight - props.shortHeight}px;
  }
  &.tall {
    height: ${(props) => props.tallHeight}px;
    top: 0px;
  }
`;

const DrawerHandle = styled.div`
  width: 100%;
  height: 20px;
  min-height: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
`;

const DrawerKnob = styled.div`
  cursor: pointer;
  width: 35%;
  height: 5px;
  background-color: #cccccc;
  border-radius: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
`;
