import { useState, useRef, useEffect } from "react";

import { CenterPopup, FloatingPanel } from "antd-mobile";

import getIcontSVG from "./IconHelper";

import styled from "styled-components";

function DrawerHeader(props) {
  /*
  props taken should be:
  drawertall: boolean
  duration: number
  planned: number
  remaining: number
  spent: number
  date: date
  category: string
  changeDate: () => void
  changePlanned: () => void
  */

  //will need to account for the different categories
  const SVG = getIcontSVG(props.category);
  return (
    <MainContainer>
      <DatePicker onClick={props.editDate}>&lt; {props.date} &gt;</DatePicker>

      <Title>
        <TitleIcon>
          <SVG />
        </TitleIcon>
        {props.category}
      </Title>
      <NumbersDisplay>
        <NumberDisplay onClick={props.editPlanned}>
          <p>${props.planned}</p>
          <p>Planned</p>
        </NumberDisplay>

        <CenterCircleContainer>
          <CenterCircle
            className={props.drawerTall ? "tall" : "short"}
            duration={props.duration}
            category={props.category}
          >
            <CenterNumberDisplay>
              <p className="big">${props.remaining}</p>
              <p>Remaining</p>
            </CenterNumberDisplay>
          </CenterCircle>
        </CenterCircleContainer>

        <NumberDisplay>
          <p>${props.spent}</p>
          <p>Spent</p>
        </NumberDisplay>
      </NumbersDisplay>
    </MainContainer>
  );
}

export default DrawerHeader;

const MainContainer = styled.div`
  width: 95%;
  background-color: #f0f7ef;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DatePicker = styled.div`
  color: #b7b7b7;
  margin-top: 6px;
  font-size: 1em;
`;

const Title = styled.div`
  font-size: 1.5em;
  color: black;
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
`;

const TitleIcon = styled.svg`
  height: 20px;
  width: 20px;
  margin-right: 10px;
`;

const NumbersDisplay = styled.div`
  background-color: #e3efd0;
  width: 100%;
  height: 50px;
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
`;

const NumberDisplay = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const CenterCircleContainer = styled.div`
  width: 30%;
  position: relative;
`;

const CenterCircle = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  aspect-ratio: 2 / 1;

  transition:
    height ${(props) => props.duration}s,
    border-radius ${(props) => props.duration}s;
  &.short {
    height: 100%;
  }
  &.tall {
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    height: 120%;
  }

  background-color: ${(props) =>
    props.category === "All" ? "#34ac51" : "#fc7f19"};
  // background-color: #34ac51;
  overflow: visible;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CenterNumberDisplay = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .big {
    font-size: 2em;
  }
`;
