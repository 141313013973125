import styled from "styled-components";

/* IN: progress: 0 - 100, is how far the highlighted progress bar is
 */
function ProgressBar({ progress }) {
  return (
    <ProgressBar1 id="progressBar">
      <HighlightedProgressBar progress={progress} />
    </ProgressBar1>
  );
}

const ProgressBar1 = styled.div`
  position: relative;
  width: 52%; /* Krista's Figma: 223/430 = 52% */
  max-width: 600px;
  height: 1.52vh; /* 14/921 */
  margin: 1em auto;

  /* responsive version for ipad/tablet sizes */
  /*
  @media (min-width: 768px) {
    margin: 0.9em auto;
  }
  */
  /* responsive version for desktop sizes */
  /*
  @media (min-width: 1200px) {
    margin: 0.6em auto;
  }
  */

  background-color: var(--grey-progress-bar-background);
  border-radius: 7px;
`;

const HighlightedProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${(props) => props.progress}%;
  height: 100%;
  background: rgba(79, 129, 180, 0.75);
  border-radius: 7px;
`;

export default ProgressBar;
