import { Tooltip } from "antd";
import { ReactComponent as EditIcon } from "../../assets/img/Other/edit-icon.svg";
import styled from "styled-components";

export default function EditIconSmall({ handleClick }) {
  return (
    <PlannedEdit>
      <Tooltip title="Edit">
        <EditIcon
          height="16"
          width="16"
          style={{ cursor: "pointer" }}
          onClick={handleClick}
        />
      </Tooltip>
    </PlannedEdit>
  );
}

const PlannedEdit = styled.span`
  display: inline-block;
  svg,
  path,
  def {
    display: inline-block;
  }
`;
