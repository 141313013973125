import React, { useCallback, useContext, useEffect, useState } from 'react';
import { usePlaidLink, PlaidLinkOnSuccess, PlaidLinkOnExit } from 'react-plaid-link';
import { useNavigate } from "react-router-dom";
import { useApp } from "../../state/AppProvider";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { useDisplayErrorMsg } from "../../hooks/useDisplayErrorMsg";

import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { SimpleFormFooter } from "../../components/UI/FormFooter";
import backend from "../../functions/backend";
import { isNull } from "../../functions/utils";
import MyPlaidContext from "../Plaid";
import { getMoneyFlowToken } from '../../state/stateApplication';
import { DataPrep } from "./DataPrep";
import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";

import { SubheaderText, BodyRegularText } from '../../styles/styledText';
import styled from "styled-components";
import "../../styles/IPadLook.css";
import * as SP from "../../styles/styledPageComponents";
import { SyncOutlined } from '@ant-design/icons';

var connectAccountBtn = false;
const LinkAccount = () => {
  const [showLetsSyncMsg, setShowLetsSyncMsg] = useState(true);
  const [showSyncingMsg, setShowSyncingMsg] = useState(false);
  const [whileSyncingMsg, setWhileSyncingMsg] = useState(
    "Your transactions are in safe hands with our bank-level security and strong encryption.");
  const { showErrorMsg } = useApp();

  const navigate = useNavigate();
  const { linkToken, isPaymentInitiation, dispatch } = useContext(MyPlaidContext);
  const checkResponseFail = useCheckResponseFail();
  const { displayErrorMsg } = useDisplayErrorMsg();

  // get link_token from MF server when component mounts
  useEffect(() => {
    const createLinkToken = async () => {
      console.log("Getting link token from our backend.");
      let response = await backend.post("/v1/plaid/link_token", {});
      checkResponseFail(response, "Failed to get link token:");

      console.log("Got response:", response);
      console.log("dispatch:", dispatch);
      let new_link_token;

      if (isNull(response.link_token) || isNull(response.link_token.link_token)) {
        console.log("link_token is null:", response);
        new_link_token = null;
        displayErrorMsg("Link token is null!");

      } else {
        new_link_token = response.link_token.link_token;
      }

      console.log("dispatch setting linkToken:", new_link_token);
      dispatch({ type: "SET_STATE", state: { linkToken: new_link_token } });
      // XXX Do we need to keep this locally for future oauth?
      //localStorage.setItem("link_token", response.link_token);
      console.log("config.token:", config.token);
    };
    createLinkToken();
  }, [dispatch, navigate]);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(async (publicToken: string, metadata) => {
    console.log("Success on Plaid! Got public token:", publicToken);
    console.log("With metadata:", metadata);
    // send public_token to MF server
    // https://plaid.com/docs/api/tokens/#token-exchange-flow
    const exchangePublicTokenForAccessToken = async () => {
      console.log("Sending public token to our backend.");
      let response = await backend.post("/v1/plaid/link", { public_token: publicToken });
      if (!response.success) {
        dispatch({
          type: "SET_STATE",
          state: {
            itemId: `no item_id retrieved`,
            accessToken: `no access_token retrieved`,
            isItemAccess: false,
          },
        });
      }
      checkResponseFail(response, "Failed to save public / access token:");

      console.log("Got response:", response);
      dispatch({
        type: "SET_STATE",
        state: {
          itemId: response.item_id,
          accessToken: response.access_token,
          isItemAccess: true,
        },
      });
    };

    // 'payment_initiation' products do not require the public_token to be exchanged for an access_token.
    if (isPaymentInitiation) {
      console.log("isPaymentInitiation so no need to exchange public token for access token.");
      dispatch({ type: "SET_STATE", state: { isItemAccess: false } });
    } else {
      console.log("Exchanging public token for access token.");
      exchangePublicTokenForAccessToken();
    }

    console.log("Link success.");
    dispatch({ type: "SET_STATE", state: { linkSuccess: true } });
    //  alert("You have successfully linked your accounts! Press Next to continue.");
    setShowLetsSyncMsg(false);
    setShowSyncingMsg(true);
    connectAccountBtn = true;
  }, [navigate, dispatch, isPaymentInitiation]);

  const onExit = useCallback<PlaidLinkOnExit>(async (error, metadata) => {
    console.log("Incomplete on Plaid! Got error:", error);
    console.log("With metadata:", metadata);
    dispatch({
      type: "SET_STATE",
      state: {
        itemId: `no item_id retrieved`,
        accessToken: `no access_token retrieved`,
        isItemAccess: false,
      },
    });

    if ((error != null) && (error.error_code === 'INVALID_LINK_TOKEN')) {
      // The user exited the Link flow with an INVALID_LINK_TOKEN error.
      // This can happen if the token expires or the user has attempted
      // too many invalid logins.
      // Get a new link_token.
      //createLinkToken();
    }

    //  alert("No accounts were linked. Try again later on your Accounts page. Press Next to continue.");
    setShowLetsSyncMsg(false);
    setShowSyncingMsg(false);
    displayErrorMsg("Could not connect your accounts. Please try again now." +
       "  Or you can try again later by going to the Accounts Page.");
  }, [dispatch]);

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken!,
    onSuccess: onSuccess,
    onExit: onExit,
  };

  console.log("config.token:", config.token);
  const { open, ready } = usePlaidLink(config);

  function navBack() {
    navigate("/money-wheel-setup");

  }

  function navNext() {
    open()
    //navigate("/expense-review");
  }

  return (
    <div className="screen">
      { showSyncingMsg && <DataPrep setWhileSyncingMsg={setWhileSyncingMsg} />}
      <MoneyFlowHeader />
        <ErrorNoticeBar />
      <SP.FormWrapper2>
        { showSyncingMsg &&
          <>
            <LoadingIcon>
              <SyncOutlined spin />
            </LoadingIcon>
            <Text0> Syncing ...  </Text0>
            <Text1>
              We are downloading your transactions to your MoneyFlow account now.
            </Text1>
          </>
        }
        { showLetsSyncMsg &&
          <Text0>
            Let's sync your accounts
          </Text0>
        }
        { (showLetsSyncMsg || showSyncingMsg) &&
          <Text1>{whileSyncingMsg}</Text1>
        }
        {/*
        { showErrorMsg &&
          <Text1>
            Could not connect your accounts. Please try again now.  Or you can
            try again later by going to the Accounts Page in the menu
          </Text1>
        }
        */}
        <SimpleFormFooter
          onPrevButton={navBack}
          onNextButton={navNext}
          disabledNextButton={connectAccountBtn}
          disabledPrevButton={true}
          nextBtnText={"Connect accounts"}
        />
      </SP.FormWrapper2>
    </div>
  );
};

export default LinkAccount;

const LoadingIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.5em;
  font-size: 7rem;
`;

const Text0 = styled.div`
  ${SubheaderText};
  margin-top: 2em;
`;

const Text1 = styled.div`
  ${BodyRegularText};
  margin-top: 1em;
`;
