import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";

import * as SP from "../../styles/styledPageComponents";
import { PageTitle, BodyText } from "../../styles/styledPageComponents";
import "../../styles/IPadLook.css";
import { Form, Button } from "antd";

function HelpThankYou() {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const [form1] = Form.useForm();

  async function onFinish(values) {
    navigate("/money-wheel");
  }

  function navBack() {
    navigate("/money-wheel");
  }

  function onFinishFailed(errorInfo) {}

  return (
    <div className="screen">
      <MoneyFlowHeader />
      {/*
      <BackArrow src="/back_arrow.svg" onClick={navBack} alt="back button" />
      */}
      <SP.ScrollingAboveBottomNavBar>
        <Form
          style={{ maxWidth: 430, width: "100%" }}
          layout="vertical"
          name="form_registration"
          form={form1}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <PageTitle>Thank you for contacting us</PageTitle>
          <BodyText>
            We have received your question. Your ticket number is {ticketId}.
            Our customer support team will get back to you soon.
          </BodyText>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              OK
            </Button>
          </Form.Item>
        </Form>
      </SP.ScrollingAboveBottomNavBar>
      <BottomNavBar />
    </div>
  );
}

export default HelpThankYou;

/*
const BackArrow = styled.img`
  align-self: flex-start;
`;
*/
