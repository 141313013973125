// After 1 second, focus the element with the specified id.
// Focus means that the input element is active and ready
// to receive user input.
//
// IN: select: boolean: set this to true, to also select and
// highlight the text inside of the input element.
// By default, select is false.
export function focusTextBoxes(id, select = false) {
  function focusDomObj() {
    let textboxDomObj = document.getElementById(id);
    if (textboxDomObj) {
      textboxDomObj.focus();
      if (select) {
        textboxDomObj.select();
      }
      textboxDomObj = null;
    }
  }
  setTimeout(focusDomObj, 1000);
}

// For the element with the specified id,
// if flag is true, show the element (as a block element).
// If flag is false, hide the element.
export function setDisplay(id, flag) {
  var domObj = document.getElementById(id);
  if (domObj) {
    if (flag) {
      domObj.style.display = "block";
    } else {
      domObj.style.display = "none";
    }
    domObj = null;
  }
}
