export function setMoneyFlowToken(token) {
  console.log("setMoneyFlowToken", token);
  localStorage.setItem("token", token);
}

export function getMoneyFlowToken() {
  const token = localStorage.getItem("token");
  //console.log("getMoneyFlowToken", token);
  return token;
}
