// Also see os.js
// for OS detection (Windows, Linux, Mac)
// and device detection (isDesktop())

export function isGoodBrowser() {
  var chrome = Boolean(window.navigator.userAgent.indexOf("Chrome/") !== -1);
  var safari = Boolean(window.navigator.userAgent.indexOf("Safari/") !== -1);
  if (chrome) {
    var chromeVerNumber = parseInt(
      window.navigator.userAgent
        .match(/Chrome\/[0-9]+/)[0]
        .replace("Chrome/", ""),
    );
    console.log(`chromeVerNumber = ${chromeVerNumber}`);
  }
  if (safari) {
    var safariVerNumber = parseInt(
      window.navigator.userAgent
        .match(/Safari\/[0-9]+/)[0]
        .replace("Safari/", ""),
    );
    console.log(`safariVerNumber = ${safariVerNumber}`);
  }

  var viewportWidth = window.innerWidth;
  var viewportHeight = window.innerHeight;
  var isGoodWidth = Boolean(viewportWidth >= 360 && viewportWidth <= 768);
  var isGoodHeight = Boolean(viewportHeight >= 720 && viewportHeight <= 1024);
  console.log("viewport dimensions ok?:", isGoodWidth, isGoodHeight);

  return (
    ((chrome && chromeVerNumber >= 109) || (safari && safariVerNumber >= 16)) &&
    isGoodWidth &&
    isGoodHeight
  );
}
