import { useState, useEffect } from "react";
import { sortByDateThenBySplitFromTxId } from "./tableColumns";

const useDraggableExpense = (expenseData) => {
  // draggableExpense is the first row in the table, that may be sorted by column.
  const [draggableExpense, setDraggableExpense] = useState(null);
  const [currentSorter, setCurrentSorter] = useState();

  useEffect(() => {
    if (!expenseData?.length) {
      //console.log("no expenses, so no draggableExpense.");
      setDraggableExpense(null);
      return;
    }

    if (!currentSorter) {
      const sortedData = [...expenseData].sort((a, b) => {
        // For Date, defaultSortOrder is "descend"
        const multiplier = -1;
        return sortByDateThenBySplitFromTxId(a, b) * multiplier;
      });

      const firstRow = sortedData.length > 0 ? sortedData[0] : null;
      setDraggableExpense(firstRow);
      return;
    }

    const sortedData = [...expenseData].sort((a, b) => {
      const multiplier = currentSorter.order === "ascend" ? 1 : -1;
      return currentSorter.column.sorter(a, b) * multiplier;
    });

    const firstRow = sortedData.length > 0 ? sortedData[0] : null;
    setDraggableExpense(firstRow);
  }, [expenseData, currentSorter]);

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(
      "handleTableChange(): Various parameters",
      pagination,
      filters,
      sorter,
    );
    setCurrentSorter(sorter);
  };

  return { handleTableChange, draggableExpense };
};

export default useDraggableExpense;
