import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import backend from "../../functions/backend";
import { getLastNumMonthsQueryString } from "../../functions/timeDate";

// Will repeatedly check for uncategorized data from the last 2 months.
// If not enough uncategorized data, it'll relabel some data as "uncategorized".
// It will try to ensure there is at least MIN_UNCATEGORIZED_TRANSACTIONS

export const DataPrep = ({ setWhileSyncingMsg }) => {
  const navigate = useNavigate();
  const checkResponseFail = useCheckResponseFail();
  const [numPollsRun, setNumPollsRun] = useState(0);

  const MIN_UNCATEGORIZED_TRANSACTIONS = 2;
  const MAX_UNCATEGORIZED_TRANSACTIONS = 15;

  // Returns the number of uncategorized tx for the last 2 months.
  // Asks for 2 months, since there will be only a few tx if it's
  // early in the current month.
  const fetchDataCount = async () => {
    console.log("Data count is being fetched.");
    const query =
      "/v1/tx/transaction?categorization_type=uncategorized&_count=true&posted_date=" +
      getLastNumMonthsQueryString(2);
    let response = await backend.get(query);
    console.log("DataPrep fetchDataCount response is");
    console.log(response);

    // for testing, simulate no tx
    //return 0;

    if (
      checkResponseFail(
        response,
        "Failed to GET uncategorized transactions count:",
      )
    ) {
      console.error("Error fetching data count:");
      return 0;
    }
    return response.count;
  };

  const relabelDataUncategorized = async () => {
    console.log("Categorized Data is being fetched.");
    // get up to MAX_UNCATEGORIZED_TRANSACTIONS most recent tx from the last 2 months
    const query =
      "/v1/tx/transaction?_limit=" +
      MAX_UNCATEGORIZED_TRANSACTIONS +
      "&_sort_by=posted_date&_sort_order=desc&posted_date=" +
      getLastNumMonthsQueryString(2);
    let response = await backend.get(query);
    console.log("relabelDataUncategorized response is");
    console.log(response);
    checkResponseFail(
      response,
      "Failed to GET categorized transactions.  No expenses after 30 sec.",
    );
    console.log("response.items");
    console.log(response.items);
    let convertedCount = 0;
    if (response.items) {
      for (
        let i = 0;
        i < response.items.length &&
        convertedCount < MIN_UNCATEGORIZED_TRANSACTIONS;
        i++
      ) {
        let expense = response.items[i];
        if (
          expense.plaid_tx_id === "fake_income_tx_id" ||
          expense.counterparty.includes("Employer")
        ) {
          continue; // skip income items
        }

        // Convert the expense to uncategorized,
        // so there is something for the user to sort in
        // Initial Expense Review.
        let data = {
          id: expense.id,
          tx_category_id: 0,
          categorization_type: "uncategorized",
          tx_subcategory_id: 0,
        };
        console.log("Starting to make expense uncategorized again.");
        let response2 = await backend.patch("/v1/tx/transaction", data);
        checkResponseFail(response2, "Failed to PATCH transaction:");
        if (response2.success) {
          convertedCount++;
        }
      }
    }
    if (convertedCount === 0) {
      // Ask about adding another account through Plaid
    } else {
      navigate("/expense-review-info");
    }
  };

  useEffect(() => {
    console.log("DataPrep is rendering");
  });

  useEffect(() => {
    console.log("useEffect(): 0.   DataPrep component is mounted.");
    const MAX_POLLING_DURATION = 29000; // 29 sec
    const POLLING_INTERVAL = 6000; // 6 sec
    // poll every 6 sec, up to 29 sec.

    // This runs every 6 sec
    const poll = async () => {
      setNumPollsRun((numPollsRun) => numPollsRun + 1);
      console.log("New Poll is running.  Fetching data count.");
      const dataCount = await fetchDataCount();
      console.log("Data was fetched.");
      if (dataCount > MIN_UNCATEGORIZED_TRANSACTIONS) {
        console.log("Data is good.");
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        navigate("/expense-review-info");
      } else {
        console.log("Not enough data.");
      }
    };

    // do next polls every 6 sec
    const intervalId = setInterval(poll, POLLING_INTERVAL);

    // stop polling after 29 sec
    const timeoutId = setTimeout(() => {
      console.log("Time is up.");
      clearInterval(intervalId);

      // Couldn't find the MIN_UNCATEGORIZED_TRANSACTIONS,
      // so we'll take some categorized transactions
      // and make them uncategorized, so that the
      // initial expense review can use them.

      relabelDataUncategorized();
    }, MAX_POLLING_DURATION);

    poll(); // do first poll immediately

    // This cleanup function runs when the component is unmounted
    // and every time the useEffect runs again.
    return () => {
      console.log("DataPrep cleanup function is running.");
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    // make new message appear
    const msgs = [
      "Your transactions are in safe hands with our bank-level security and strong encryption.",
      "Ready to take charge of your finances?  You got this!",
      "Budgeting isn't about limiting yourself- it's about making your goals that excite you possible.",
      "Transfer of financial transactions from Plaid servers are taking longer than expected. Thank you for your patience.",
      "Grow your savings by spending less each month.  Research shows that tracking your expenses can reduce spending by 15%.",
      "Couldn't retrieve any transactions from Plaid.  You have no expenses to review.",
    ];
    setWhileSyncingMsg(msgs[numPollsRun]);
  }, [numPollsRun]);

  return null;
};
