import { createContext, useContext, useReducer } from "react";
import backend from "../functions/backend";

// IN: dependent id array
async function addDependentsInfo(user, dependentIdArray) {
  // get back all dependents
  let response = await backend.get("/v1/dependent");
  // response.items is the array of dependents.
  // It's more than an array of ids.
  // It's an array of the Dependent objects.

  if (response.success) {
    console.log(
      "Number of dependents = response.items.length =",
      response.items.length,
    );
    user.dependentAges = [];
    for (let item of response.items) {
      // get the age and save it into user's dependentAges array
      user.dependentAges.push(item.age);
    }
  } else {
    console.warn("Failed to get dependent:", response);

    if (response.http_status_code === 401) {
      //navigate("/");
    } else {
      alert(response.message);
    }
  }
}

// This is called after the user logs in,
// because we need to know which userID.
// If userID is undefined, then use the
// currently logged in user.
export async function initUser(userID) {
  let queryString = userID ? "?id=" + userID : "";
  let response = await backend.get("/v1/user" + queryString);

  if (!response.success) {
    console.warn("initUser(): Failed to get user:", response);
    //navigate("/");
  }

  // if endpoint is "/v1/user"
  // response.items is a 1 item array containing the current user,
  let user = response.items[0];
  console.log("initUser(): user =", user);
  let user_id = user.id;
  console.log("initUser(): user_id =", user_id);

  await addDependentsInfo(user, user.dependents);

  return user;
}

const UserContext = createContext({});

function UserProvider({ children }) {
  // reducer should not have any side effects
  const reducer = (state, action) => {
    let userCopy;
    switch (action.type) {
      case "USER_REGISTER":
      case "USER_LOGIN":
        console.log("reducer case USER_LOGIN");
        console.log(action.payload.user);
        console.log("setting new user");

        // Since React wants state to be immutable,
        // make a deep copy of the state value fields
        userCopy = structuredClone(action.payload.user);
        return userCopy;

      case "USER_LOGOUT":
        return {};

      case "CFT_SETUP":
        console.log("reducer case CFT_SETUP");
        console.log("action.payload");
        console.log(action.payload);
        console.log("currentPage");
        console.log(action.payload.currentPage);
        console.log("values");
        console.log(action.payload.values);
        // marital_status: "married"
        console.log("returning");
        console.log({ ...state, ...action.payload.values });
        console.log("===================");
        return { ...state, ...action.payload.values };

      case "CFT_SETUP_HOW_MANY_DEPENDENTS":
        // Delete the excess dependents from user.dependents[] and user.dependentAges[].
        let numDependents = action.payload.numDependents;
        userCopy = structuredClone(state);
        userCopy.dependents = userCopy.dependents.slice(0, numDependents);
        userCopy.dependentAges = userCopy.dependentAges.slice(0, numDependents);
        return userCopy;

      case "CFT_SETUP_AGE_OF_DEPENDENT":
        console.log("reducer case CFT_SETUP_AGE_OF_DEPENDENT");
        console.log("action.payload");
        console.log(action.payload);

        // Update User's dependentAges[] and dependents[] arrays
        // when changing age or adding dependent.
        userCopy = structuredClone(state);
        userCopy.dependentAges = action.payload.ages;
        userCopy.dependents = action.payload.dependentIds;
        return userCopy;

      case "CFT_SETUP_VEHICLES":
        console.log("reducer case CFT_SETUP_VEHICLES");
        let vid = action.payload.id;
        console.log(`vid is ${vid}`);
        userCopy = structuredClone(state);
        if (vid) {
          // empty array
          console.assert(
            Array.isArray(userCopy.vehicles) && !userCopy.vehicles.length,
            "userCopy.vehicles array should be empty",
          );
          userCopy.vehicles.push(vid);
        } else {
          console.assert(
            Array.isArray(userCopy.vehicles) && userCopy.vehicles.length === 1,
            "userCopy.vehicles array should have 1 element",
          );
          userCopy.vehicles = [];
        }
        return userCopy;

      case "CFT_SETUP_VEHICLE_LOANS":
        console.log("reducer case CFT_SETUP_VEHICLE_LOANS");
        let vlid = action.payload.id;
        console.log(`vlid is ${vlid}`);
        userCopy = structuredClone(state);
        if (vlid) {
          // vehicleLoans might not exist or it's an empty array.
          // Or it might have 1 id value in the array, if the user hits the Back
          // button and revisits this page.

          // So, need to create vehicleLoans, rather than push an id value to it,
          // since it might not exist in User yet.
          userCopy.vehicleLoans = [vlid];
        } else {
          console.assert(
            Array.isArray(userCopy.vehicleLoans) &&
              userCopy.vehicleLoans.length === 1,
            "userCopy.vehicleLoans array should have 1 element",
          );
          userCopy.vehicleLoans = [];
        }
        return userCopy;

      case "PROFILE":
        console.log("reducer case PROFILE");
        console.log("action.payload");
        console.log(action.payload);

        console.log("returning");
        console.log({ ...state, ...action.payload });
        console.log("===================");
        return { ...state, ...action.payload };

      default:
        return state;
    }
  };
  const [currentUser, dispatchUser] = useReducer(reducer, {});

  return (
    <UserContext.Provider value={{ currentUser, dispatchUser }}>
      {children}
    </UserContext.Provider>
  );
}

// Define custom hook to allow access to the values
// made available by UserProvider.
// useContext is how you access a context value.
// So now you don't need to export UserContext.
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export default UserProvider;
