import React from "react";
import { Layout } from "antd";
import AdminFooter from "./layout/AdminFooter";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import AdminNotificationsContent from "./AdminNotificationsContent";

export default function AdminPage() {
  return (
    <>
      <Layout>
        <MoneyFlowHeader />
        <AdminNotificationsContent />
        <AdminFooter />
      </Layout>
    </>
  );
}
