import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";

const { Content } = Layout;

export default function AdminNotificationsContent() {
  return (
    <Content
      style={{
        margin: "24px 16px 0",
        minHeight: "75vh",
        overflow: "initial",
      }}
    >
      <div
        className="site-layout-background"
        style={{ padding: 24, textAlign: "left" }}
      >
        <h1>Admin Notifications</h1>
        <Link to="/admin">Back to Admin Dashboard</Link>
      </div>
    </Content>
  );
}
