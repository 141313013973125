import { InfoTitle, InfoText } from "../CFTSetup/Forms/styledFormComponents";

//import htmr from "htmr";
const monthmap = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export default function MonthReviewComponent(props) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "flex-start",
        }}
      >
        {/*
        <InfoTitle>
          test
        </InfoTitle>
        */}
        <p
          style={{ fontWeight: "bold", fontSize: "20px", marginBottom: "none" }}
        >
          {monthmap[props.numMonth - 1]} {props.currentYear} MoneyFlow
        </p>
        <p
          style={{
            color: props.center > 0 ? "green" : "red",
            fontWeight: "800",
            fontSize: "20px",
            marginTop: "0px",
          }}
        >
          {props.center > 0
            ? `$${props.center}`
            : `-$${Math.abs(props.center)}`}
        </p>
        <InfoText>
          {parseInt(props.center) <= 0 && (
            <span class="overBudget"> You're a bit over this month</span>
          )}
          {parseInt(props.center) > 0 && (
            <span class="underBudget">Nice work staying on budget!</span>
          )}
        </InfoText>
        <InfoText>
          Get help from the community to make next month even better!
        </InfoText>
        <button
          onClick={() => {
            props.acknowledgeMonthReview();
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
}
