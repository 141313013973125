import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../state/UserProvider";
import { useSettings, updateSetupStep1 } from "../../state/SettingsProvider";
import useExpenseData from "./useExpenseData";
import useDraggableExpense from "../ActiveTracker/useDraggableExpense";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { FloatingPanel } from "antd-mobile";
import { Form, Table } from "antd";
import { hide, show } from "../../functions/visfunctions";
import { focusTextBoxes } from "../../functions/dom";
import { ErrorNoticeBar } from "../../components/UI/ErrorNoticeBar";

import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { TopNavBarWheels } from "../../components/PageComponents/TopNavBar";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";

import ActiveWheel from "../Tracker/ActiveWheel";
import { ExpenseReviewForm } from "../ExpenseReview/Forms/ExpenseReviewForm";
import Drawer from "./NewUIHelpers/Drawer";

//import { UseCategoryContext } from "./CategoryProvider";
import { usePollingA } from "./PollingProvider";
import InitTrackerConfig from "../ExpenseReview/InitTrackerConfig";
import backend from "../../functions/backend";
import {
  setMoneyFlowToken,
  getMoneyFlowToken,
} from "../../state/stateApplication";
import {
  getMonthReviewStatus,
  updateMonthReview,
} from "../../functions/monthReviewHelper";
import MonthReviewComponent from "./MonthReviewComponent";
import BudgetProgressTab from "./BudgetProgressTab";
import EditIconSmall from "./EditIconSmall";
import ExpenseDetailsModal, {
  ExpenseDetailsCircleModal,
} from "./Modals/ExpenseDetailsModal";
import DateChangerModal from "./Modals/DateChangerModal";
import PlanChangerModal from "./Modals/PlanChangerModal";
import SplitModal from "./NewUIHelpers/SplitModal";

import { ID_LIST, CAT_LIST, monthABV, monthNameLookUp } from "./constants";
import { createColumnsCat } from "./tableColumns";
import { get1MonthQueryString } from "../../functions/timeDate";

import { iconRef } from "./icons";
import styled from "styled-components";
import "../../styles/IPadLook.css";
import { Screen } from "../../styles/styledScreen";

var currentCategory = "All";

var currentDate = new Date();
var currentMonth = monthABV[currentDate.getMonth()];
var currentYear = currentDate.getFullYear();
var currentDay = 1;
var daysLeft = 0;
var numMonth = currentDate.getMonth() + 1;
var OverBudget = false;

var Budget = {
  Uncategorized: 0,
  Personal: 0,
  Food: 0,
  Home: 0,
  Entertainment: 0,
  Health: 0,
  Transport: 0,
  Utility: 0,
  Other: 0,
  center: 0,
  days: daysLeft,
};

function DateChanger({ handleClick, currYear, currMonth }) {
  return (
    <div onClick={handleClick}>
      <span>{currMonth}</span>&nbsp;
      <span>{currYear}</span>
    </div>
  );
}

function TestActiveTracker() {
  const navigate = useNavigate();
  //const { CategoryInputs, initCategory, saveCategoryInputs } =
  //UseCategoryContext();
  const { startPolling, isPolling, numUncategorizedTx, setNumUncategorizedTx } =
    usePollingA();

  const [cat, setCat] = useState(8);
  const [expenseData, setExpenseData] = useState([]);
  const [expenseTableFlag, setExpenseTableFlag] = useState(false);
  const [aYears, setAYears] = useState([]);
  const [maxSplit, setMaxSplit] = useState({});

  const [combined, setCombined] = useState([]);
  const [isModalOpenDateChanger, setIsModalOpenDateChanger] = useState(false);
  const [isModalOpenDetails, setIsModalOpenDetails] = useState(false);
  const [isModalOpenDetailsCircle, setIsModalOpenDetailsCircle] =
    useState(false);
  const [isModalOpenPlanned, setIsModalOpenPlanned] = useState(false);
  const [editID, setEditID] = useState(0);
  const { handleTableChange, draggableExpense } =
    useDraggableExpense(expenseData);
  const splitModal = useRef(null);
  const showSplitModal = () => {
    if (splitModal.current) splitModal.current.showModal();
  };
  const hideSplitModal = () => {
    if (splitModal.current) splitModal.current.close();
  };

  var [budgetID, setBudgetID] = useState(0);
  var [budgetFiguresState, setbudgetFiguresState] = useState({});
  var [budgetFiguresAllState, setbudgetFiguresAllState] = useState({});

  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [form3] = Form.useForm();
  const [form4] = Form.useForm();

  const { currentUser } = useUser();
  const { wheelSettings, dispatchSettings } = useSettings();
  const { formatForTable } = useExpenseData();
  const checkResponseFail = useCheckResponseFail();
  // const [isTour, setIsTour] = useState(
  //   wheelSettings.setup_step === wheelSettings.SETUP_STEP_ACTIVE_WHEEL_TOUR,
  // );

  const [isMonthlyReview, setIsMonthlyReview] = useState(false);
  const [id, setId] = useState(-1);

  //checking if we need to do a month review
  useEffect(() => {
    async function fetchData() {
      const date = new Date();
      const month = date.getMonth();
      const year = date.getFullYear();
      //for actual use, use this
      let temp = await getMonthReviewStatus(month, year);
      //for testing use this:
      //End of month review TEST testing
      // let temp = await getMonthReviewStatus(5, 2024);
      if (temp >= 0) {
        setId(temp);
        //we want the drawers to disappear when we are doing the month review
        //TODO remove Drawer in month review

        //have to set the numMonth and currentYear
        numMonth = month === 1 ? 12 : month - 1;
        currentYear = month === 1 ? year - 1 : year;
        //display the info for the correct month
        await getBudget();
        // await getCurrentCategoryExpenses(cat);
      }
      setIsMonthlyReview(temp >= 0);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!isPolling) {
      console.log("startPolling()");
      startPolling();
    }
  }, []);

  useEffect(() => {
    // runs when component unmounts
    return () => {
      console.log("ActiveTracker is unmounting.");
    };
  }, []);

  // useEffect(() => {
  //   setIsTour(
  //     wheelSettings.setup_step === wheelSettings.SETUP_STEP_ACTIVE_WHEEL_TOUR,
  //   );
  // }, [wheelSettings.setup_step]);

  // useEffect(() => {
  //   if (!isTour) {
  //     console.log("useEffect().  isTour is false");
  //     if (getMoneyFlowToken() === "") {
  //       navigate("/");
  //     } else {
  //       updateSetupStep1(
  //         wheelSettings.SETUP_STEP_ACTIVE_WHEEL_MAIN,
  //         currentUser.money_wheel_settings_id,
  //         dispatchSettings,
  //       );
  //     }
  //   }
  // }, [isTour]);

  // Handle user refresh
  useEffect(() => {
    window.addEventListener("beforeunload", handleRefresh);
    return () => {
      window.removeEventListener("beforeunload", handleRefresh);
    };
  }, []);

  const handleRefresh = (e) => {
    setMoneyFlowToken("");
    e.returnValue = true;
  };

  const [RemainingBudget, setRemainingBudget] = useState({
    Uncategorized: 0,
    Personal: 0,
    Food: 0,
    Home: 0,
    Entertainment: 0,
    Health: 0,
    Transport: 0,
    Utility: 0,
    Other: 0,
    center: 0,
  });

  var budgetFigures = {
    budgetAmount: 0,
    actualSpend: 0,
    remaining: 0,
    projectedSpend: 0,
    projected: 0,
    projectedRemaining: 0,
  };
  var budgetFiguresAll = {
    budgetAmount: 0,
    actualSpend: 0,
    remaining: 0,
    projectedSpend: 0,
    projected: 0,
    projectedRemaining: 0,
  };

  const getDays = (year, month) => new Date(year, month, 0).getDate();

  async function setCurrentDate() {
    currentDate = new Date();
    // currentDay = getDays(currentDate.getFullYear(), currentDate.getMonth() + 1);
    currentDay = currentDate.getDate();
    daysLeft =
      getDays(currentDate.getFullYear(), currentDate.getMonth() + 1) -
      currentDate.getDate();
    currentMonth = monthABV[currentDate.getMonth()]; // eg "Jan"
    numMonth = currentDate.getMonth() + 1; // 1
    currentYear = currentDate.getFullYear(); // 2024

    form1.setFieldsValue({ month: currentMonth, year: currentYear });
    // getEndOfMonth(currentYear, currentDate);
    await getCurrentCategoryExpenses(cat);
  }
  // async function getEndOfMonth(currentYear, currentDate) {
  //   let response = await backend.get("/v1/money_wheel_settings");
  //   if (response.items) {
  //     if (
  //       12 === response.items[0].last_review_month &&
  //       response.items[0].last_review_year < currentYear &&
  //       1 <= currentDate.getMonth() + 1
  //     ) {
  //       show("EndOfMonthReview");
  //     } else if (
  //       response.items[0].last_review_month < currentDate.getMonth() + 1 &&
  //       response.items[0].last_review_year <= currentYear
  //     ) {
  //       show("EndOfMonthReview");
  //       response = await backend.patch("/v1/money_wheel_settings", {
  //         last_review_month: currentDate.getMonth() + 1,
  //         last_review_year: currentYear,
  //         id: response.items[0].id,
  //       });
  //     }
  //   }
  // }

  const showModal = () => {
    setIsModalOpenDateChanger(true);
  };

  const showModalPlanned = () => {
    if (cat !== "All") {
      form3.setFieldsValue({
        planned: parseFloat(budgetFiguresState.budgetAmount),
      });

      setIsModalOpenPlanned(true);
      focusTextBoxes("PlannedChanger_planned", true);
    }
  };

  async function onFinishPlanned(values) {
    if (budgetID == 0) {
      console.log("Can't patch budget with id = 0!");
      //  alert("Can't patch budget with id = 0!");
    }

    let query = "/v1/budget";
    const params = {
      tx_category_id: cat,
      budget_amount: String(values.planned),
      is_set_by_user: true,
      year: currentYear,
      month: numMonth,
      id: budgetID,
    };
    const budget = await backend.patch(query, params);
    setIsModalOpenPlanned(false);
    await getBudgetCategory();
  }

  const onChangeMonth = async (str, obj1) => {
    if (str) {
      currentMonth = str;

      if (!isCurrentMonth()) {
        currentDay = getDays(currentYear, monthNameLookUp[currentMonth]);
      } else {
        currentDay = currentDate.getDate();
      }

      numMonth = monthNameLookUp[currentMonth];
      //  currentDay = getDays(currentDate.getFullYear(), numMonth);
      form1.setFieldsValue({ month: currentMonth, year: currentYear });
      await getBudget();
      await getCurrentCategoryExpenses(cat);
      //setIsModalOpenDateChanger(false);
    }
  };

  const onChangeYear = async (str, obj1) => {
    if (str) {
      currentYear = str;
      if (!isCurrentMonth()) {
        currentDay = getDays(currentYear, monthNameLookUp[currentMonth]);
      } else {
        currentDay = currentDate.getDate();
      }

      form1.setFieldsValue({ month: currentMonth, year: currentYear });
      await getBudget();
      await getCurrentCategoryExpenses(cat);
      //  setIsModalOpenDateChanger(false);
    }
  };

  function setDateToCurrent() {
    setCurrentDate();
    //setIsModalOpenDateChanger(false);
  }

  // Sets the form fields for the Expense Details modal.
  // Opens the modal.
  // IN: id: the id of the expense
  // IN: isCallerTable is true when invoked from a row in the table
  //     isCallerTable is false when invoked from the circle modal
  async function editRow(id, isCallerTable = true) {
    setEditID(id);
    let query = "/v1/tx/transaction?id=" + id;
    const response = await backend.get(query);

    let label = response.items[0].label;
    if (!label) label = response.items[0].counterparty;
    setMaxSplit(parseFloat(response.items[0].amount));
    const values = {
      splitAmount: parseFloat(response.items[0].amount),
      label: label,
    };
    if (isCallerTable) {
      // handle additional form field initialization
      // for the modal for editing the table row
      values.category = response.items[0].tx_category_id;
      setIsModalOpenDetails(true);
      form2.setFieldsValue(values);
    } else {
      setIsModalOpenDetailsCircle(true);
      form4.setFieldsValue(values);
    }
  }

  async function handleSplit(values) {
    let splitParams = {
      original_tx_id: editID,
      new_amount: String(values.splitAmount),
    };
    if (values.category) {
      splitParams.new_tx_category_id = values.category;
      splitParams.new_tx_subcategory_id = values.category * 100;
    }
    if (values.label) {
      splitParams.new_label = values.label;
    }
    let querySplit = "/v1/tx/split";

    const responseSplit = await backend.post(querySplit, splitParams);
    checkResponseFail(responseSplit, "Failed to split transaction:");
    console.log(
      `handleSplit() is calling getCurrentCategoryExpenses(cat = ${cat})`,
    );
    getCurrentCategoryExpenses(cat);
  }

  async function onFinishEditDetails(values) {
    console.log("onFinishEditDetails()");
    // necessary for modals that don't have a category field
    if (values.category === undefined) values.category = 0;
    let query = "/v1/tx/transaction?id=" + editID;
    const response = await backend.get(query);
    const originalAmount = parseFloat(response.items[0].amount);
    if (values.splitAmount >= 0.01 && values.splitAmount < originalAmount) {
      handleSplit(values);
    } else {
      let data = {
        id: editID,
        tx_category_id: values.category,
        tx_subcategory_id: values.category * 100,
        label: values.label,
      };
      if (values.category) {
        data.categorization_type = "user_manual";
      }
      console.log("data1:", data);
      const r1 = await backend.patch("/v1/tx/transaction", data);
      if (!checkResponseFail(r1, "Failed to PATCH transaction:")) {
        getBudget();
      }
      console.log(
        `onFinishEditDetails() is calling getCurrentCategoryExpenses(cat = ${cat})`,
      );
      getCurrentCategoryExpenses(cat);
    }

    // if in ExpenseReview mode, then update the drawer of expenses
    if (numUncategorizedTx) getUncategorizedExpenses();

    setIsModalOpenDetails(false);
    setIsModalOpenDetailsCircle(false);
  }

  async function init() {
    //  setCat("Personal")
    // setCurrentDate();
  }

  window.onload = init;

  const switchCategory = (name, num) => {
    console.log("switchCategory to " + num.toString());
    if (num) {
      hide("expenseDataTable");
      show("combinedDataTable");
      setExpenseTableFlag(false);
      currentCategory = name;
      getBudgetCategory();
      setCat(num);
      init();
      getCurrentCategoryExpenses(num);
    }
  };

  function navBack() {
    setExpenseTableFlag(true);
    show("expenseDataTable");
    hide("combinedDataTable");
  }

  async function getAvailableYears() {
    const availableYears = await backend.get("/v1/budget?available_years=true");
    checkResponseFail(availableYears, "Failed to GET budget available_years.");

    let ret = [];
    if (availableYears.items) {
      for (let i = 0; i < availableYears.items[0].length; i++) {
        ret.push({
          value: availableYears.items[0][i],
          label: availableYears.items[0][i],
        });
      }
    }

    setAYears(ret);
  }

  // Gets all the expenses for the Category during the selected month.
  // The input, Category, can be a specific category or "All".
  // Updates the drawer.
  async function getCurrentCategoryExpenses(Category) {
    console.log("getCurrentCategoryExpenses for category: " + Category);
    let query =
      "/v1/tx/transaction?posted_date=" +
      get1MonthQueryString(numMonth, currentYear) +
      "&_sort_by=posted_date&_sort_order=desc";

    if (Category !== "All") {
      query += "&tx_category_id=" + Category;
    }

    console.log("query: " + query);
    const response = await backend.get(query);
    console.log(response);
    if (
      checkResponseFail(
        response,
        "Failed to GET current category transactions:",
      )
    ) {
      return;
    }

    console.log("Got " + response.items.length + " transactions.");

    const tableData = formatForTable(response.items);

    setCombined(tableData);
  }

  // This is called when user clicks on any part of the wheel (category section or center).
  //
  // Get the budget for the specified month (numMonth, currentYear) and currentCategory.
  //
  // This sets both global budgetFigures and React state budgetFiguresState
  // TODO: try to use just 1
  async function getBudgetCategory() {
    console.log("getBudgetCategory: " + currentCategory);
    let query = "/v1/budget?year=" + currentYear + "&month=" + numMonth;
    if (currentCategory !== "All") {
      query += "&tx_category_id=" + ID_LIST[currentCategory];
    }
    const response = await backend.get(query);
    checkResponseFail(response, "Failed to GET budget year and month.");

    if (response.items) {
      // response.items.length:
      // array length will be 1, since we are getting a specific month
      // and category, so there is only 1 budget...
      // or array length will be 9, if we are getting all categories.

      //console.log(
      //  "getBudgetCategory(): response.items.length =",
      //  response.items.length,
      //);

      for (let i = 0; i < response.items.length; i++) {
        //console.log("getBudgetCategory(): ");
        //console.log("response.items[i]");
        //console.log(response.items[i]);

        //console.log("setBudgetID to " + response.items[i].id);

        setBudgetID(response.items[i].id);
        budgetFigures.budgetAmount += parseFloat(
          response.items[i].budget_amount,
        );
        budgetFigures.actualSpend += parseFloat(
          response.items[i].actual_amount,
        );
        budgetFigures.remaining += parseFloat(
          response.items[i].budget_amount - response.items[i].actual_amount,
        );
        budgetFigures.projected += parseFloat(response.items[i].budget_amount);
        budgetFigures.projectedRemaining += parseFloat(
          response.items[i].budget_amount - response.items[i].actual_amount,
        );
        budgetFigures.projectedSpend += parseFloat(
          // TODO: 'All' is typo?
          budgetFiguresAll.projected_amount,
        );
      }
      for (let bf in budgetFigures) {
        if (budgetFigures[bf]) {
          // budgetFigures[bf] = parseInt(budgetFigures[bf]);
          budgetFigures[bf] = Math.round(budgetFigures[bf]);
        }
      }

      setbudgetFiguresState(budgetFigures);
    }
  }

  // Get the budget for the specified month (numMonth, currentYear) for all categories.
  //
  // This sets both global budgetFiguresAll and React state budgetFiguresAllState
  // TODO: try to use just 1
  async function getBudget() {
    const response = await backend.get(
      "/v1/budget?year=" + currentYear + "&month=" + numMonth,
    );
    checkResponseFail(response, "Failed to GET budget year and month.");

    let sum = 0;
    let budgetSum = 0;
    console.log("budget response");
    console.log(response.items);
    let TempRemainingBudget = {
      Uncategorized: 0,
      Personal: 0,
      Food: 0,
      Home: 0,
      Entertainment: 0,
      Health: 0,
      Transport: 0,
      Utility: 0,
      Other: 0,
      center: 0,
    };
    if (response.items) {
      //console.log(
      //"getBudget(): response.items.length =",
      //response.items.length,
      //);

      for (let i = 0; i < response.items.length; i++) {
        //console.log("getBudget(): ");
        //console.log("response.items[i]");
        //console.log(response.items[i]);

        //  if (
        //    response.items[i].year == currentYear &&
        //    monthABV[parseInt(response.items[i].month) - 1] == currentMonth
        //  ) {
        Budget[CAT_LIST[parseInt(response.items[i].tx_category_id)]] +=
          parseFloat(response.items[i].actual_amount);
        //}
        // set the remaining budget for the category and update the total amount
        const remaining =
          parseFloat(response.items[i].budget_amount) -
          parseFloat(response.items[i].actual_amount);
        TempRemainingBudget[
          CAT_LIST[parseInt(response.items[i].tx_category_id)]
        ] = remaining;
        TempRemainingBudget.center += remaining;

        budgetFiguresAll.budgetAmount += response.items[i].budget_amount;
        budgetFiguresAll.actualSpend += response.items[i].actual_amount;
        budgetFiguresAll.remaining +=
          response.items[i].budget_amount - response.items[i].actual_amount;

        budgetFiguresAll.projected += response.items[i].budget_amount;
        budgetFiguresAll.projectedRemaining +=
          response.items[i].budget_amount - response.items[i].actual_amount;
        budgetFiguresAll.projectedSpend += budgetFiguresAll.projected_amount;

        budgetSum += parseFloat(response.items[i].actualSpend);
      }
      for (let x in Budget) {
        if (x !== "center" && x !== "days") {
          sum += Budget[x];
        }
      }

      Budget.center = sum;
      Budget.budgetSum = budgetSum;
      Budget.budgetDifference = sum - budgetSum;
      Budget.budgetDifference = Budget.budgetDifference.toFixed(2);

      OverBudget = Boolean(Budget.budgetSum < Budget.center);

      setRemainingBudget(TempRemainingBudget);
      //saveCategoryInputs(Budget);
      setExpenseTableFlag(true);

      for (let bf in budgetFiguresAll) {
        if (budgetFiguresAll[bf]) {
          budgetFiguresAll[bf] = parseInt(budgetFiguresAll[bf]);
        }
      }
      setbudgetFiguresAllState(budgetFiguresAll);
    }
  }

  useEffect(() => {
    console.log("useEffect called for comined or expenseData change.");
  }, [combined, expenseData]);

  useEffect(() => {
    setCurrentDate();
    getAvailableYears();
    //initCategory();
    getBudget();
    show("tab1");
    show("tab3");
    switchCategory("All", "All");

    // Is this getting called before the layout is complete? no
  }, []);

  useEffect(() => {
    console.log(
      "useEffect called to switch drawers because numUncategorizedTx changed.",
    );
    if (expenseData.length < numUncategorizedTx) {
      getUncategorizedExpenses();
    }
    if (numUncategorizedTx) {
      show("expenseDataTable");
      hide("combinedDataTable");
    } else {
      hide("expenseDataTable");
      show("combinedDataTable");
    }
  }, [numUncategorizedTx]);

  // Gets uncategorized expenses, up to 15, for the current month.
  // Stores the data in local ExpenseData and React state expenseData.
  // expenseData only has uncategorized expenses.
  // If there a 0 expenses, goes to active tracker mode.
  async function getUncategorizedExpenses() {
    console.log("getExpenses for " + currentYear + "-" + numMonth);
    const response = await backend.get(
      "/v1/tx/transaction?categorization_type=uncategorized&_limit=15&posted_date=" +
        get1MonthQueryString(numMonth, currentYear),
    );
    // FYI: to trigger error msg, set categorization_type=foo
    if (
      checkResponseFail(response, "Failed to GET uncategorized transactions:")
    )
      return null;

    const ExpenseData = formatForTable(response.items);
    setExpenseData(ExpenseData);
    setNumUncategorizedTx(ExpenseData.length);
    if (ExpenseData.length === 0) {
      console.log("getUncategorizedExpenses(): 0 uncategorized expenses.");
      hide("expenseDataTable");
      show("combinedDataTable");
    }
  }

  async function onFinish(values) {
    console.log("onFinish() has values:");
    console.log(values);
    const currentPage = "ExpenseReviewForm";
    console.log("currentPage: ", currentPage);
    show("expenseDataTable");
  }

  const trackerConfig = InitTrackerConfig();

  async function callbackDropExpense(expense, category, array = expenseData) {
    if (expense) {
      console.log("callbackDropExpense", expense, category, array);

      let data = {
        id: expense.key,
        tx_category_id: ID_LIST[category],
        categorization_type: "user_manual",
        tx_subcategory_id: parseInt(ID_LIST[category]) * 100,
      };
      const response = await backend.patch("/v1/tx/transaction", data);
      if (!checkResponseFail(response, "Failed to PATCH transaction:")) {
        const newExpenseData = array.filter((item) => item.id !== expense.id);
        setNumUncategorizedTx(numUncategorizedTx - 1);
        // NOTE: array[0] is undefined, after dragging the last uncategorized
        // expense into a category.
        setEditID(array[0] ? array[0].key : 0);
        setExpenseData(newExpenseData);
        getBudget();

        // FYI: setExpenseData is done asynchronously, so the log below may not be
        // accurate yet.
        console.log("EXPENSE ARRAY: ", expenseData);

        if (newExpenseData.length === 0) {
          console.log("All expenses categorized");
        }
      }

      //this makes it so that drawer will display the new expense when it is dragged in
      if (ID_LIST[category] === cat) {
        console.log("refreshing category expenses");
        console.log("category: ", category);
        getCurrentCategoryExpenses(cat);
      }
    }
  }

  function isCurrentMonth() {
    return (
      monthABV[currentDate.getMonth()] === currentMonth &&
      currentDate.getFullYear() === currentYear
    );
  }

  return (
    <Screen>
      <MoneyFlowHeader />
      <TopNavBarWheels />
      <ErrorNoticeBar />
      {/* <SplitModal ref={splitModal}  close={()=> hideSplitModal}/>
      <button onClick={()=>{
        showSplitModal();
      }} style={{"position": "relative", "zIndex": 1500}}>Toggle Split Modal</button> */}
      <DateChangerModal
        form1={form1}
        isOpen={isModalOpenDateChanger}
        setIsOpen={setIsModalOpenDateChanger}
        currentMonth={currentMonth}
        onChangeMonth={onChangeMonth}
        currentYear={currentYear}
        onChangeYear={onChangeYear}
        aYears={aYears}
        setDateToCurrent={setDateToCurrent}
      />
      <ExpenseDetailsModal
        form2={form2}
        isOpen={isModalOpenDetails}
        setIsOpen={setIsModalOpenDetails}
        onFinish={onFinishEditDetails}
        maxSplit={maxSplit}
      />
      <ExpenseDetailsCircleModal
        form4={form4}
        isOpen={isModalOpenDetailsCircle}
        setIsOpen={setIsModalOpenDetailsCircle}
        onFinish={onFinishEditDetails}
        maxSplit={maxSplit}
      />
      <PlanChangerModal
        form3={form3}
        isOpen={isModalOpenPlanned}
        setIsOpen={setIsModalOpenPlanned}
        onFinish={onFinishPlanned}
      />

      <div className="tracker-form-split ActiveTracker">
        {/* ActiveWheel, so you can have a tour */}
        <ActiveWheel
          dropExpense={callbackDropExpense}
          draggableExpense={draggableExpense}
          config={trackerConfig}
          switchCategory={switchCategory}
          showNumbers={true}
          categoryFigures={null}
          remainingBudget={RemainingBudget}
          // isTour={isTour}
          // setIsTour={setIsTour}
          isTour={false}
          setIsTour={() => {}}
          navBack={navBack}
          isExpenseReview={false}
          wheelType={"MoneyWheel"}
          EditFunction={editRow}
        />
        {expenseData.length !== 0 && (
          <ExpenseReviewForm
            onFinish={onFinish}
            previousPage={() => {}}
            expenseData={expenseData}
          />
        )}
      </div>
      {isMonthlyReview && (
        <MonthReviewComponent
          numMonth={numMonth}
          currentYear={currentYear}
          center={Math.round(RemainingBudget.center)}
          acknowledgeMonthReview={() => {
            const date = new Date();
            const month = date.getMonth();
            const year = date.getFullYear();
            numMonth = month;
            currentYear = year;

            setIsMonthlyReview(false);
            console.log("clicked");
            updateMonthReview(month, year, id);
            // updateMonthReview(1, 1, id);
            console.log(id);
            getBudget();
            // TODO make drawer appear
          }}
        />
      )}
      {/*
      <div style={{ minHeight: "90px" }}>numUncategorizedTx: {numUncategorizedTx}</div>
      */}
      <Drawer
        // category={cat}
        category={currentCategory}
        date={currentMonth + " " + currentYear}
        planned={budgetFiguresState.budgetAmount}
        remaining={budgetFiguresState.remaining}
        spent={budgetFiguresState.actualSpend}
        editPlanned={showModalPlanned}
        editDate={showModal}
        //now for the table entries data
        tableData={[...combined]}
        editRow={editRow}
      />
      {/* <FloatingPanel
        ref={combinedDataDrawerRef}
        anchors={anchors}
        handleDraggingOfContent={false}
        style={{ "--header-height": "56px"  }}
      >
        <div id="combinedDataTable" style={{ display: "none" }}>
          <Title>
            {iconRef[currentCategory] && (
              <img
                src={iconRef[currentCategory]}
                alt=""
                width="38"
                height="38"
              />
            )}
            &nbsp;{currentCategory}
          </Title>
          <DateChangerStyle>
            <DateChanger
              handleClick={showModal}
              currYear={currentYear}
              currMonth={currentMonth}
            />
          </DateChangerStyle>

          <BudgetProgressStyle>
            <BudgetProgressTab
              budget={budgetFiguresState.budgetAmount}
              spent={budgetFiguresState.actualSpend}
              remaining={budgetFiguresState.remaining}
              editIcon={
                cat !== "All" &&
                isCurrentMonth() && (
                  <EditIconSmall handleClick={showModalPlanned} />
                )
              }
            />
          </BudgetProgressStyle>
          <TransactionsContainer>
            <Table
              dataSource={[...combined]}
              columns={createColumnsCat(editRow)}
              onChange={handleTableChange}
            />
            <DrawerSpacer></DrawerSpacer>
          </TransactionsContainer>
        </div>
        <div id="expenseDataTable" style={{ display: "none" }}>
          <BudgetProgressStyle>
          </BudgetProgressStyle>
          <TransactionsContainer>
            <Table
              dataSource={[...expenseData]}
              columns={createColumnsCat(editRow)}
              onChange={handleTableChange}
            />
            <DrawerSpacer></DrawerSpacer>
          </TransactionsContainer>
        </div>
      </FloatingPanel> */}
      <BottomNavBar />
    </Screen>
  );
}

export default TestActiveTracker;

const TransactionsContainer = styled.div`
  font-family: TitleFont;
  font-size: 1rem; /* 20/16=1.25rem */
  font-weight: 700;
  line-height: 1.6875rem; /* 27/16 */
  text-align: left;
  white-space: break-spaces;
  min-height: 200px;
  --header-height: 50px;
  .tableRowText {
    cursor: pointer;
  }
`;

const DateChangerStyle = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
`;

const DrawerSpacer = styled.div`
  min-height: 100px;
`;

const BudgetProgressStyle = styled.div`
  text-align: center;
`;

const Title = styled.div`
  text-align: center;
  display: block;
  font-size: 24pt;
`;
