import { setMoneyFlowToken } from "../state/stateApplication";

export class TokenTimeout {
  constructor() {
    this.duration = 3600000; // const delay time in millisec
    // 3600000 = 1 hr
    this.callback = () => {
      console.log("TokenTimeout: Logging out.");
      this.timerId = null;
      setMoneyFlowToken("");
      // It will automatically redirect to the login screen,
      // when you click the Next button
    };
    this.timerId = setTimeout(this.callback, this.duration);
    console.log("TokenTimeout: Initialized");
  }

  resetTimeout() {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(this.callback, this.duration);
      console.log("TokenTimeout: Reset existing timeout");
    }
  }

  // need this if logging out and loggin in again or registering.
  // timeout may or may not exist, when this is called.
  reinitTimeout() {
    if (this.timerId !== null) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(this.callback, this.duration);
    console.log("TokenTimeout: Reinit");
  }
}
