import React from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import { PointerStyle } from "../../styles/styledCommunity";

const ReplyControl = ({ numberOfReplies, topicId }) => {
  const navigate = useNavigate();

  const goToReplies = () => {
    navigate("/replies/" + topicId);
  };

  return (
    <div onClick={() => goToReplies()}>
      <Tooltip title="Replies">
        <PointerStyle>
          <CommentOutlined /> {numberOfReplies === 0 ? "" : numberOfReplies}
        </PointerStyle>
      </Tooltip>
    </div>
  );
};

export default ReplyControl;
