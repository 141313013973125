// This file will replace styleguide.css
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  /* Handle short phones like nHD Galaxy S7 and iPhone SE/6/7/8  (375x667).

     Specify a max-width to prevent selecting a desktop.

     Shrink everything down by the --shrink factor.
  */
  @media (max-width: 500px) and (max-height: 700px) {
    :root {
      --shrink: .8;
      /* scales all font-sizes that use rem units */
      font-size: calc(var(--shrink) * 16px);
    }
  }
`;

export default GlobalStyle;
