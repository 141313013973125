import { createContext, useContext, useState } from "react";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [moneyWheelData, setMoneyWheelData] = useState(null);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  // errorMsg clears when a new ErrorNoticeBar is created
  const [errorMsg, setErrorMsg] = useState("");
  // lastErrorMsg persists, even when a new ErrorNoticeBar is created
  const [lastErrorMsg, setLastErrorMsg] = useState("");

  return (
    <AppContext.Provider
      value={{
        moneyWheelData,
        setMoneyWheelData,
        showErrorMsg,
        setShowErrorMsg,
        errorMsg,
        setErrorMsg,
        lastErrorMsg,
        setLastErrorMsg,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Define custom hook to allow access to the values
// made available by AppProvider.
// useContext is how you access a context value.
// So now you don't need to export AppContext.
export const useApp = () => useContext(AppContext);

export default AppProvider;
