import BottomNavBar from "../../components/PageComponents/BottomNavBar";
import { useNavigate } from "react-router-dom";
import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import "../../styles/IPadLook.css";

export default function Marketplace() {
  const navigate = useNavigate();
  return (
    <div className="screen">
      <MoneyFlowHeader />
      <h2>Marketplace</h2>
      The MoneyFlow Marketplace is disabled during Early Access.
      <BottomNavBar />
    </div>
  );
}
