import styled from "styled-components";

import { forwardRef, useState } from "react";

const SplitModal = forwardRef((props, ref) => {
  return (
    <Dialog ref={ref}>
      <CircleContainer>
        <Outer height={10} width={80}>
          <CirclePiece moveRight={0} moveDown={0} />
        </Outer>
        <VerticalSpacer />
        <Row>
          <Outer height={50} width={39}>
            <CirclePiece moveRight={20.5} moveDown={-12}></CirclePiece>
            <Inner>
              <p> Transaction name</p>
              <TextEntry>
                <input defaultValue="Starbucks 1" />
              </TextEntry>
              <p> Transaction name</p>
              <p> Transaction name</p>
              <p> Transaction name</p>
            </Inner>
          </Outer>
          <HorizontalSpacer />
          <Outer height={50} width={39}>
            <CirclePiece moveRight={-20.5} moveDown={-12} />
          </Outer>
        </Row>
        <VerticalSpacer />
        <Outer height={16} width={80}>
          <CirclePiece moveRight={0} moveDown={-64} />
        </Outer>
      </CircleContainer>
      <button onClick={props.close()}>Close</button>
    </Dialog>
  );
});

export default SplitModal;

const Dialog = styled.dialog`
  border: none;
  border-radius: 10px;
  ::backdrop {
    backdrop-filter: blur(3px);
  }
  background: none;
`;

const CircleContainer = styled.div`
width: 80vw;
height 80vw;
border-radius: 50%;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const CirclePiece = styled.div`
  border-radius: 50%;
  background-color: pink;
  height: 80vw;
  width: 80vw;
  flex-shrink: 0;
  transform: translate(
    ${(props) => props.moveRight}vw,
    ${(props) => props.moveDown}vw
  );
`;
const Outer = styled.div`
position: relative;
width ${(props) => props.width}vw;
height: ${(props) => props.height}vw;
overflow: hidden;
border-radius: 5px;
display: flex;
justify-content: center;
flex-shrink: 0;
`;
const Inner = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justfiy-content: center;
  align-items: end;
  flex-direction: column;
  p {
    margin: 2px;
    font-size: 0.8em;
    color:;
  }
`;

const HorizontalSpacer = styled.div`
  width: 0px;
  flex-shrink: 0;
  flex-grow: 1;
`;
const VerticalSpacer = styled.div`
  height: 2vw;
  flex-shrink: 0;
`;

const TextEntry = styled.div`
  border-radius: 20px;
  background-color: #f2f5f3;
  border: 1px solid #d9d9d9;
  width: 80%;
  max-width: 150px;
  height: 2em;
  margin-right: 5px;
  input {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: #f2f5f3;
    text-align: center;
    &:focus {
      outline: none;
    }
  }
`;
