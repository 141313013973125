export function isNull(variable) {
  return typeof variable === "undefined" || variable === null;
}

export function isNonEmptyArray(item) {
  return Array.isArray(item) && item.length >= 1;
}

// searches list for an object item that has id=findID
// If found, it returns that item.
// If not found, returns undefined.
export function findById(list, findId) {
  for (const item of list) {
    if (item.id === findId) {
      return item;
    }
  }
  console.warn(`findById(): couldn't find item with id = ${findId} in list:`);
  console.log(list);
  return undefined;
}

/* Replaces keys in obj according to the mapping.

   IN: obj: an object
   IN: mapping: an object,
       where each key matches a key in obj.
       mapping[key] will be the new key name in obj.

  Retains properties in obj
  that aren't overwritten by the mapping.

  Adds properties in the mapping
  if they weren't already in the obj.
  Note: the value will be `undefined`.

  See test_changeKeysInObject() for sample usage.
*/
function changeKeysInObject(obj, mapping) {
  for (let i in mapping) {
    // save the property with the new key
    obj[mapping[i]] = obj[i];
    if (i in obj) {
      // delete the property with the old key
      delete obj[i];
    } else {
      console.warn(
        `changeKeysInObject(): property ${i} does not exist ` +
          `in obj.  Adding ${mapping[i]} to obj.`,
      );
    }
  }
}

function test_changeKeysInObject() {
  // Retains properties in obj
  // that aren't overwritten by the mapping.
  const obj1 = {
    firstName: "Joe",
    last_name: "Smith",
  };
  const mapping1 = {
    firstName: "first_name",
  };

  console.log(obj1);
  changeKeysInObject(obj1, mapping1);
  console.log(obj1);

  // Adds properties in the mapping
  // if they weren't already in the obj.
  // Note: the value will be `undefined`.
  const mapping2 = {
    zipCOde: "zip_code",
  };

  console.log(obj1);
  changeKeysInObject(obj1, mapping2);
  console.log(obj1);
}

//test_changeKeysInObject();
