import styled from "styled-components";

export const Screen = styled.div`
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  /* When not enough space vertically,
     pack the flex items at the start. */
  justify-content: flex-start;
  width: 100%;
  position: relative;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0 var(--padding-m);
  /* outline: 1px solid green; */

  font-family: TitleFont;

  a {
    display: contents;
    text-decoration: none;
  }

  /* Handle narrow phones like iPhone 13/ 13 Pro / 14  (390x844)
  */
  @media (max-width: 399px) and (min-height: 700px) {
    /* sample code: Make font smaller to handle text like "Home & Garden" */
    /*
    .category-title-text {
      font-size: calc(0.9 * 3rem);
      line-height: calc(0.9 * 4.125rem);
    }
    .category-title-group {
      height: 3rem;
      margin-bottom: -1.3em;
    }
    */
  }

  /* changes for full screen Pixel 2 XL */
  @media (min-width: 400px) and (max-height: 850px) {
    /* shrink the Tracker */
    .tracker-form-split.ActiveTracker .Tracker > svg {
      height: 36vh;
      width: 36vh;
    }
  }

  /* Handle short phones like nHD Galaxy S7 and iPhone SE/6/7/8  (375x667).

     Specify a max-width to prevent selecting a desktop.

     Shrink everything down by the --shrink factor.
  */
  @media (max-width: 500px) and (max-height: 700px) {
    .tracker-form-split {
      gap: 0.5em; /* mobile: vertical space between Tracker and Subcategories/Form */
    }
    /* shrink the Tracker */
    .tracker-form-split.ActiveTracker .Tracker > svg {
      height: 45vh;
      width: 45vh;
    }
  }

  /* IPAD-LOOK-ONLY:  Force everything to be <= 744px */
  /* Intentionally using 744px, instead of 800px, so that this rule
    catches all ipads and desktops. */
  @media (min-width: 744px) {
    .screen {
      max-width: 744px;
    }
  }
`;
