import { useRef } from "react";
import { Tour } from "antd";
import Tracker from "./Tracker";
import getCategoryIcons from "./MoneyWheelIcons";
import "./style.css";

import dragExpenseToCategoryVideo from "../../assets/vid/dragExpenseToCategory.webm";

export default function MoneyWheel(props) {
  const childRefs = useRef(null);

  let steps = [];
  if (childRefs.current) {
    const { ref1, ref2, ref3 } = childRefs.current;

    steps = [
      {
        title: "Money Wheel",
        description: `Welcome to your Money Wheel.  Going around the wheel, you see
           8 different expense categories, such as Food or Home.  You will
           be sorting each new expense into 1 of these categories.`,
        target: () => ref1.current,
      },
      /* XXX TODO No tap to sort yet.
      {
        title: "Expense",
        description:
          "If you have a new expense to sort, it appears in the center of your Money Wheel. You can tap it to automatically go in the 'best guess' category or drag and drop it in the category you want. If you want to label or split the expense tap the Edit button.",
        target: () => ref2.current,
      },
      */
      {
        title: "Expense",
        description: `If you have a new expense to sort, it appears in the center of your
           Money Wheel. You can drag and drop it into the category you want.
           To label or split the expense, tap the Pencil icon to edit the expense.`,
        cover: (
          <video
            //controls
            loop
            autoPlay
            muted
            width="100%"
            src={dragExpenseToCategoryVideo}
          ></video>
        ),
        target: () => ref2.current,
        placement: "top" /* "center", */,
      },
      /* XXX TODO No subcategories yet.
      {
        title: "Categories",
        description:
          "Tap on a category to see details like subcategories and the list of expenses in that category.",
        target: () => ref3.current,
      },
      */
      {
        title: "Categories",
        description: `Tap on a category and open the drawer to see category details
           and a list of expenses in that category.
           Tap on a row in the list to to see more details or edit that expense.`,
        /*
           Once you categorize an expense, you can edit it again by tapping on
           a row in the expense drawer.
           */
        target: () => ref3.current,
      },
    ];
  }

  // set the default, if isTour prop is not passed to Wheel
  let isTour = props.isTour;
  console.log("MoneyWheel: isTour =", isTour);
  if (isTour === undefined) {
    isTour = false;
  }
  console.log("MoneyWheel: isTour =", isTour);

  return (
    <>
      <Tracker
        {...props}
        categoryIcons={getCategoryIcons(props.config)}
        ref={childRefs}
      />
      <Tour
        open={isTour}
        onClose={() => props.setIsTour(false)}
        steps={steps}
        disabledInteraction={true}
        closeIcon={false}
      />
    </>
  );
}
