import carCategoryTitleIcon from "../../assets/img/MoneyWheel/money-car-icon.svg";
import entertainmentCategoryTitleIcon from "../../assets/img/MoneyWheel/money-entertainment-icon.svg";
import groceryCategoryTitleIcon from "../../assets/img/MoneyWheel/money-food-icon.svg";
import healthCategoryTitleIcon from "../../assets/img/MoneyWheel/money-health-icon.svg";
import homeCategoryTitleIcon from "../../assets/img/MoneyWheel/money-home-icon.svg";
import otherCategoryTitleIcon from "../../assets/img/MoneyWheel/money-other-icon.svg";
import singlePersonCategoryTitleIcon from "../../assets/img/MoneyWheel/money-single-icon.svg";
import utilitiesCategoryTitleIcon from "../../assets/img/MoneyWheel/money-utility-icon.svg";
import wheelIcon from "../../assets/img/Nav/wheels-icon.svg";
import incomeIcon from "../../assets/img/Other/income-icon.svg";

export const iconRef = {
  Personal: singlePersonCategoryTitleIcon,
  Food: groceryCategoryTitleIcon,
  Home: homeCategoryTitleIcon,
  Entertainment: entertainmentCategoryTitleIcon,
  Health: healthCategoryTitleIcon,
  Transport: carCategoryTitleIcon,
  Utility: utilitiesCategoryTitleIcon,
  Other: otherCategoryTitleIcon,
  All: wheelIcon,
  income: incomeIcon,
};

export const iconRefArray = [
  wheelIcon,
  singlePersonCategoryTitleIcon,
  groceryCategoryTitleIcon,
  homeCategoryTitleIcon,
  entertainmentCategoryTitleIcon,
  healthCategoryTitleIcon,
  carCategoryTitleIcon,
  utilitiesCategoryTitleIcon,
  otherCategoryTitleIcon,
  incomeIcon,
];

export const iconStringArray = [
  "All",
  "Family",
  "Food",
  "Home",
  "Entertainment",
  "Health",
  "Transport",
  "Utility",
  "Other",
];
