import React from "react";
import { Layout, Row, Col } from "antd";

const { Footer } = Layout;

export default function MainFooter() {
  return (
    <Footer>
      <Row>
        <Col span={24}></Col>
      </Row>
      <Row>
        <Col
          span={20}
          offset={2}
          style={{
            textAlign: "center",
          }}
        ></Col>
      </Row>
    </Footer>
  );
}
