//import './TrackerDemo.css';
import { useEffect } from "react";

import Tracker from "./Tracker";

function TrackerDemo() {
  useEffect(() => {});

  var expenseData = [
    { amount: 1200, description: "Rent" },
    { amount: 500, description: "Check 242" },
    { amount: 65, description: "Tony's Fuel" },
    { amount: 3, description: "Google Play" },
    { amount: 65, description: "Verizon" },
    { amount: 5, description: "MVCAD" },
    { amount: 14, description: "Suezie Chop Suey" },
    { amount: 14, description: "Super Als Big Car Was Extravaganza" },
  ];

  function callbackDropExpense(expense, category, array) {
    console.log("callbackDropExpense", expense, category, array);

    // Remove top item of array.
    expenseData.shift();
  }

  function switchCategory(categoryTitle, categoryNumber) {
    console.log("Clicked on Category", categoryTitle, categoryNumber);
  }

  var config = {
    family: {
      married: true,
      kids: 1,
    },
    transportation: "public",
    draggable: true,
    clickable: true,
    showText: true,
    strokeWidth: 6,
    color: "#4F81B4",
    lastCategoryDisplayed: 4,
  };

  return (
    <Tracker
      dropExpense={callbackDropExpense}
      config={config}
      expense={expenseData}
      switchCategory={switchCategory}
    />
  );
}

export default TrackerDemo;
