import { useNavigate } from "react-router-dom";
import { usePollingA } from "../../pages/ActiveTracker/PollingProvider";

import { ReactComponent as ProfileIcon } from "../../assets/img/Nav/profile-icon.svg";
import { ReactComponent as CommunityIcon } from "../../assets/img/Nav/community-icon.svg";
import { ReactComponent as WheelIcon } from "../../assets/img/Nav/wheels-icon.svg";
import { ReactComponent as MarketplaceIcon } from "../../assets/img/Nav/marketplace-icon.svg";
import { ReactComponent as HelpIcon } from "../../assets/img/Nav/question-mark-icon.svg";

import { Badge } from "antd";
import styled from "styled-components";

function BottomNavBarItem({ pathname, otherPathnames = null, itemText, icon }) {
  const navigate = useNavigate();
  return (
    <div
      className={
        window.location.pathname === pathname ||
        otherPathnames?.includes(window.location.pathname)
          ? "selected"
          : ""
      }
      onClick={() => {
        navigate(pathname);
      }}
    >
      {icon}
      <span className="buttonText">{itemText}</span>
    </div>
  );
}

export default function BottomNavBar() {
  const navigate = useNavigate();
  const { numUncategorizedTx } = usePollingA();
  const isMoneyWheelPage = window.location.pathname === "/money-wheel";
  const isCreditWheelPage = window.location.pathname === "/credit-wheel";
  const isGoalsWheelPage = window.location.pathname === "/goals-wheel";
  const isHelpPage =
    window.location.pathname === "/help" ||
    window.location.pathname.includes("/help-thank-you");

  return (
    <BottomNavBarStyle id="bottomNavBar">
      <BottomNavBarItem
        pathname="/marketplace"
        itemText="Marketplace"
        icon={<MarketplaceIcon />}
      />
      <BottomNavBarItem
        pathname="/community"
        itemText="Community"
        icon={<CommunityIcon />}
      />
      <div
        className={`
            ${isMoneyWheelPage ? "selected" : ""}
            ${isCreditWheelPage ? "selectedCreditWheel" : ""}
            ${isGoalsWheelPage ? "selectedGoalsWheel" : ""}
        `}
        onClick={() => {
          navigate("/money-wheel");
        }}
      >
        {isCreditWheelPage || isGoalsWheelPage ? (
          <WheelIcon />
        ) : (
          <Badge count={numUncategorizedTx} overflowCount={9}>
            {" "}
            <WheelIcon />{" "}
          </Badge>
        )}
        <span className="buttonText">Wheels</span>
      </div>
      <BottomNavBarItem
        pathname="/profile-page"
        otherPathnames={["/accounts-page", "/score", "/income"]}
        itemText="Personal"
        icon={<ProfileIcon />}
      />
      <div
        className={isHelpPage ? "selected" : ""}
        onClick={() => {
          const currentPage = window.location.pathname;
          console.log("Clicking on Help icon: currentPage is ", currentPage);
          navigate("/help", { state: { currentPage } });
          //navigate("/help", { state: {n, s, b}});
        }}
      >
        <HelpIcon />
        <span className="buttonText">Help</span>
      </div>
    </BottomNavBarStyle>
  );
}

const BottomNavBarStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  /* max-width: 1500px; */ /* for responsive desktops */
  /* max-width: 500px; */ /* for mobile-look on desktop */
  max-width: 760px; /* to accommodate wide drawer on ipads and desktop */

  display: flex;
  align-items: center;
  z-index: 1500;
  background: #fff;

  div {
    flex: 1; /* equal width items */
    max-width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: var(--padding-s);
    padding-bottom: 0.4em;

    text-align: center;
    color: #d9d9d9; /* text color  is too light grey to read */
    color: #cccccc; /* mid-light grey */
    color: #bbbbbb;
  }
  span.buttonText {
    font-size: 0.7rem;
    letter-spacing: -0.05em;
    font-family: PrimaryVariableFont;
    font-weight: 400;
  }
  .selected,
  .selected svg,
  .selected > span.buttonText {
    color: #25b764; /* green from the original icon */
    color: #70c037; /* lime green from the Jira-810 picture */
    font-weight: 900;
  }
  .selectedCreditWheel,
  .selectedCreditWheel svg,
  .selectedCreditWheel > span.buttonText {
    color: #991d00; /* yellow from the original icon */
    color: #ffe655; /* yellow from the wheel */
    font-weight: 900;
  }
  .selectedGoalsWheel,
  .selectedGoalsWheel svg,
  .selectedGoalsWheel > span.buttonText {
    color: #004099; /* blue from the original icon */
    color: #a9d0ff; /* blue from the wheel */
    font-weight: 900;
  }
  svg {
    color: #d9d9d9; /* light grey */
    height: 2.5em;
    width: 2.5em;
    margin-bottom: 0.3em;
  }
  border-top: 1px solid #ccc;
`;
