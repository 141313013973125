import MoneyFlowHeader from "../../components/PageComponents/MoneyFlowHeader";
import { TopNavBarPersonal } from "../../components/PageComponents/TopNavBar";
import BottomNavBar from "../../components/PageComponents/BottomNavBar";
import { PageTitle, BodyText } from "../../styles/styledPageComponents";
import "../../styles/IPadLook.css";
import { Screen } from "../../styles/styledScreen";

export default function ScorePage() {
  return (
    <Screen>
      <MoneyFlowHeader />
      <TopNavBarPersonal />
      <PageTitle>MoneyFlow Score</PageTitle>
      <BodyText>Coming Soon</BodyText>
      <BottomNavBar />
    </Screen>
  );
}
